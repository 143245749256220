import React, {FC} from "react";
import {Button, DialogActions, Modal, Paper, Typography} from "@mui/material";
import {ModelVersion} from "../../../types/models";


type DeleteModelModalProps = {
    deleteModalOpen: boolean,
    handleCloseFromProps: () => void,
    currentModel: ModelVersion | null,
    handleDeleteModelVersionFromProps: () => void,
    handleCancelDeleteModelVersionFromProps: () => void
}

const DeleteModelModal:FC<DeleteModelModalProps> = (
    {
        deleteModalOpen,
        handleCloseFromProps,
        currentModel,
        handleDeleteModelVersionFromProps,
        handleCancelDeleteModelVersionFromProps
    }) => {

    return (
        <Modal
                open={deleteModalOpen}
                onClose={handleCloseFromProps}
                aria-labelledby="delete-model-modal-title"
                aria-describedby="delete-model-modal-description"
                sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '5px 10px'}}
            >
                <Paper sx={{padding: 4, paddingLeft: 4, paddingRight: 4, minWidth: '20%'}}>
                    <Typography id="delete-model-modal-title" variant="h6" component="h2" sx={{marginBottom: 2}}>
                        Delete model version {currentModel?.version}?
                    </Typography>
                    <Typography id="delete-model-modal-description" sx={{ mb: 4 }}>
                        This action is irreversible!
                    </Typography>
                    <DialogActions>
                        <Button sx={{backgroundColor: 'rgba(255, 86, 48, 0.16)', padding: 1, paddingLeft: 2, paddingRight: 2, color: 'rgb(183, 29, 24)', fontWeight: 700}}
                                onClick={handleDeleteModelVersionFromProps}>
                            Delete
                        </Button>
                        <Button sx={{backgroundColor: '#cecece', padding: 1, paddingLeft: 2, paddingRight: 2, color: '#000000', fontWeight: 700}}
                                onClick={handleCancelDeleteModelVersionFromProps} autoFocus>
                            Cancel
                        </Button>
                    </DialogActions>
                </Paper>
            </Modal>
    )
}

export default DeleteModelModal