import React, {FC, useContext, useEffect, useState} from "react";
import {FormControl, Grid, InputLabel, MenuItem, Select, SelectChangeEvent} from "@mui/material";
import '../../../styles/admin-dashboard.css'
import {styled} from "@mui/material/styles";
import {outlinedInputClasses} from "@mui/material/OutlinedInput";
import {StationContext} from "../../../context/StationsContext";
import {airPollutionApi} from "../../../utils/axios";
import {
    MonitorDataDisplayType,
    MonitorDataType,
    MonitorViewType,
    PredictedDataDisplayType, PredictedDataResponseObjectType
} from "../../../types/monitor";
import {useResponsive} from "../../../hooks/responsive";
import ModelMonitorGraph from "./ModelMonitorGraph";
import {StationType} from "../../../types/stations";
import ModelValuesGraph from "./ModelValuesGraph";


type AdminMainDashboardProps = {}

export const StyledSelect = styled(Select)(`
  & .${outlinedInputClasses.notchedOutline} {
    border-color: #0F123F;
  }
  &:hover .${outlinedInputClasses.notchedOutline} {
    border-color: #0F123F;
  }
  &.${outlinedInputClasses.focused} .${outlinedInputClasses.notchedOutline} {
    border-color: #0F123F;
  }
`);

const AdminMainDashboard:FC<AdminMainDashboardProps> = () => {
    const { stations } = useContext(StationContext)
    const [station, setStation] = useState<string>('E403')
    const [monitorData, setMonitorData] = useState<MonitorDataDisplayType | null>(null)
    const [predictedData, setPredictedData] = useState<PredictedDataDisplayType | null>(null)
    const [viewType, setViewType] = useState<MonitorViewType>(MonitorViewType.VALUES)
    const isMd = useResponsive('up', 'md')
    const isLg = useResponsive('up', 'lg')
    const isXl = useResponsive('up', 'xl')
    const isSm = useResponsive('up', 'sm')
    const isXs = useResponsive('up', 'xs')

    const handleStationChange = (event: SelectChangeEvent<unknown>) => {
        setStation(event.target.value as string);
    };

    const fetchMonitorData = async () => {
        const fetchedResult = await airPollutionApi.post('/models/model-metrics', {station})
        const fetchedData = fetchedResult.data.data

        convertToMonitorData(fetchedData)
    }

    const fetchPredictionData = async () => {
        try {
            const fetchedResult = await airPollutionApi.get(`/models/predicted-values/${station}`)
            let fetchedData = fetchedResult.data

            const convertedData = convertToPredictionData(fetchedData)

            setPredictedData(convertedData)
        } catch (err: any) {
            console.log('error', err)
        }

    }

    const convertToPredictionData = (fetchedData: Array<PredictedDataResponseObjectType>) => {
        let tempPredictionArray: PredictedDataDisplayType = {
            PM10: [],
            "PM2.5": [],
            date: [],
            pred_pm10_champ: [],
            pred_pm10_pro: [],
            pred_pm25_champ: [],
            pred_pm25_pro: [],
        }

        fetchedData.sort((date1, date2) =>  { // @ts-ignore
            return (new Date(date1.date) - new Date(date2.date))}).forEach((fetchedItem: PredictedDataResponseObjectType) => {
            tempPredictionArray.date.push(new Date(fetchedItem.date))

            tempPredictionArray.PM10.push(fetchedItem.PM10)
            tempPredictionArray["PM2.5"].push(fetchedItem["PM2.5"])
            tempPredictionArray.pred_pm10_pro.push(fetchedItem.pred_pm10_pro)
            tempPredictionArray.pred_pm25_pro.push(fetchedItem.pred_pm25_pro)
            tempPredictionArray.pred_pm10_champ.push(fetchedItem.pred_pm10_champ)
            tempPredictionArray.pred_pm25_champ.push(fetchedItem.pred_pm25_champ)
        })

        return tempPredictionArray
    }

    const getGraphWidth = () => {
        if (isXl) return 530
        if (isLg) return 450
        if (isMd) return 300
        if (isSm) return 400
        if (isXs) return 300

        return 530
    }

    const getValuesGraphWidth = () => {
        if (isXl) return 1200
        if (isLg) return 900
        if (isMd) return 500
        if (isSm) return 500
        if (isXs) return 300

        return 530
    }

    const convertToMonitorData = (fetchedData: Array<MonitorDataType>) => {
        let tempMonitoringArray: MonitorDataDisplayType = {
            dates: [],
            pm10_pro: {
                mae: [],
                mse: [],
                evs: []
            },
            pm25_pro: {
                mae: [],
                mse: [],
                evs: []
            },
            pm10_champ: {
                mae: [],
                mse: [],
                evs: []
            },
            pm25_champ: {
                mae: [],
                mse: [],
                evs: []
            }
        }

        fetchedData.sort((date1, date2) =>  { // @ts-ignore
            return (new Date(date1.datetime) - new Date(date2.datetime))}).forEach((fetchedItem: MonitorDataType) => {
            tempMonitoringArray.dates.push(new Date(fetchedItem.datetime))

            tempMonitoringArray.pm10_pro.evs.push(fetchedItem.pm10_pro.evs)
            tempMonitoringArray.pm10_pro.mae.push(fetchedItem.pm10_pro.mae)
            tempMonitoringArray.pm10_pro.mse.push(fetchedItem.pm10_pro.mse)

            tempMonitoringArray.pm25_pro.evs.push(fetchedItem.pm25_pro.evs)
            tempMonitoringArray.pm25_pro.mae.push(fetchedItem.pm25_pro.mae)
            tempMonitoringArray.pm25_pro.mse.push(fetchedItem.pm25_pro.mse)

            if(fetchedItem.pm10_champ) {
                tempMonitoringArray.pm10_champ.evs.push(fetchedItem.pm10_champ.evs)
                tempMonitoringArray.pm10_champ.mae.push(fetchedItem.pm10_champ.mae)
                tempMonitoringArray.pm10_champ.mse.push(fetchedItem.pm10_champ.mse)
            } else {
                tempMonitoringArray.pm10_champ.evs.push(null)
                tempMonitoringArray.pm10_champ.mae.push(null)
                tempMonitoringArray.pm10_champ.mse.push(null)
            }

            if(fetchedItem.pm25_champ) {
                tempMonitoringArray.pm25_champ.evs.push(fetchedItem.pm25_champ.evs)
                tempMonitoringArray.pm25_champ.mae.push(fetchedItem.pm25_champ.mae)
                tempMonitoringArray.pm25_champ.mse.push(fetchedItem.pm25_champ.mse)
            } else {
                tempMonitoringArray.pm25_champ.evs.push(null)
                tempMonitoringArray.pm25_champ.mae.push(null)
                tempMonitoringArray.pm25_champ.mse.push(null)
            }
        })

        setMonitorData(tempMonitoringArray)
    }

    const handleViewTypeChange = (event: SelectChangeEvent<unknown>) => {
        setViewType(event.target.value as MonitorViewType)
    }

    useEffect(() => {
        if(viewType === MonitorViewType.METRICS)
            fetchMonitorData()
        if(viewType === MonitorViewType.VALUES)
            fetchPredictionData()
    }, [station, viewType])

    return (
        <Grid container sx={{
            padding: isSm ? 6 : 2,
            rowGap: 7,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-evenly',
            maxHeight: '100%',
            overflowY: 'scroll',
            backgroundColor: '#F7F7F7',
            paddingBottom: isMd ? 4 : 12
        }}>
            <Grid item xs={12} sx={{
                display: 'flex',
                flexDirection: isMd ? 'row' : 'column',
                justifyContent: 'center',
                alignItems: 'center',
                marginRight: {
                    xs: '5%',
                    md: '20%'
                },
                marginLeft: {
                    xs: '5%',
                    md: '20%'
                },
                columnGap: 3,
                rowGap: 2
            }}>
                <Grid item xs={12} md={3} sx={isMd ? {} : {width: '100%'}}>
                    <FormControl fullWidth sx={{borderColor: '#0F123F'}}>
                        <InputLabel id="admin-particles-select-label" sx={{color: '#0F123F'}}>
                            Type
                        </InputLabel>
                        <StyledSelect
                            labelId="admin-view-type-select-label"
                            id="admin-view-type-select"
                            value={viewType}
                            label="Type"
                            onChange={handleViewTypeChange}
                            sx={{
                                backgroundColor: '#fff',
                                borderColor: '#0F123F',
                                fontWeight: 700,
                                textTransform: 'uppercase',
                                letterSpacing: '2px'
                            }}
                            fullWidth={!isMd}
                            MenuProps={{ PaperProps: { sx: {maxHeight: 300 }}}}
                        >
                            <MenuItem className={'admin-station-menu-item'} value={MonitorViewType.METRICS}>Metrics</MenuItem>
                            <MenuItem className={'admin-station-menu-item'} value={MonitorViewType.VALUES}>Values</MenuItem>
                        </StyledSelect>
                    </FormControl>
                </Grid>
                <Grid item xs={12} md={9} sx={isMd ? {} : {width: '100%'}}>
                    <FormControl fullWidth sx={{borderColor: '#0F123F'}}>
                        <InputLabel id="admin-station-select-label" sx={{color: '#0F123F'}}>
                            Station
                        </InputLabel>
                        <StyledSelect
                            labelId="admin-station-select-label"
                            id="admin-station-select"
                            value={station}
                            label="Station"
                            onChange={handleStationChange}
                            sx={{
                                backgroundColor: '#fff',
                                borderColor: '#0F123F',
                                fontWeight: 700,
                                textTransform: 'uppercase',
                                letterSpacing: '2px'
                            }}
                            MenuProps={{ PaperProps: { sx: {maxHeight: 300 }}}}
                        >
                            <MenuItem className={'admin-station-menu-item'} value={'--'}>Choose a station</MenuItem>
                            {stations.map((stationItem: StationType) => {
                                return (
                                    <MenuItem key={stationItem.label + '-menu-item'} className={'admin-station-menu-item'} value={stationItem.serial}>{stationItem.label}</MenuItem>
                                )
                            })}
                        </StyledSelect>
                    </FormControl>
                </Grid>
            </Grid>

            {(monitorData && viewType === MonitorViewType.METRICS) &&
                <>
                    <ModelMonitorGraph
                        dates={monitorData.dates}
                        pro_data={monitorData.pm10_pro.evs}
                        champ_data={monitorData.pm10_champ.evs}
                        graphWidth={getGraphWidth()}
                        title={'EVS for PM10'}
                    />

                    <ModelMonitorGraph
                        dates={monitorData.dates}
                        pro_data={monitorData.pm25_pro.evs}
                        champ_data={monitorData.pm25_champ.evs}
                        graphWidth={getGraphWidth()}
                        title={'EVS for PM2.5'}
                    />

                    <ModelMonitorGraph
                        dates={monitorData.dates}
                        pro_data={monitorData.pm10_pro.mae}
                        champ_data={monitorData.pm10_champ.mae}
                        graphWidth={getGraphWidth()}
                        title={'MAE for PM10'}
                    />

                    <ModelMonitorGraph
                        dates={monitorData.dates}
                        pro_data={monitorData.pm25_pro.mae}
                        champ_data={monitorData.pm25_champ.mae}
                        graphWidth={getGraphWidth()}
                        title={'MAE for PM2.5'}
                    />

                    <ModelMonitorGraph
                        dates={monitorData.dates}
                        pro_data={monitorData.pm10_pro.mse}
                        champ_data={monitorData.pm10_champ.mse}
                        graphWidth={getGraphWidth()}
                        title={'MSE for PM10'}
                    />

                    <ModelMonitorGraph
                        dates={monitorData.dates}
                        pro_data={monitorData.pm25_pro.mse}
                        champ_data={monitorData.pm25_champ.mse}
                        graphWidth={getGraphWidth()}
                        title={'MSE for PM2.5'}
                    />
                </>
            }

            {(predictedData && viewType === MonitorViewType.VALUES) &&
                <Grid item xs={12} sx={{display: 'flex', flexDirection: 'column', gap: 2, justifyContent: 'space-between'}}>
                    <ModelValuesGraph
                        dates={predictedData.date}
                        pro_data={predictedData.pred_pm10_pro}
                        champ_data={predictedData.pred_pm10_champ}
                        actual_data={predictedData.PM10}
                        graphWidth={getValuesGraphWidth()}
                        title={'Values for PM10'}
                    />

                    <ModelValuesGraph
                        dates={predictedData.date}
                        pro_data={predictedData.pred_pm25_pro}
                        champ_data={predictedData.pred_pm25_champ}
                        actual_data={predictedData["PM2.5"]}
                        graphWidth={getValuesGraphWidth()}
                        title={'Values for PM2.5'}
                    />

                </Grid>
            }
        </Grid>
    )
}

export default AdminMainDashboard