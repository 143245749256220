import React, {FC} from "react";
import {LineChart} from "@mui/x-charts";


type CustomLineChartProps = {
    data: Array<number | null>,
    dates: Array<Date | null>,
    height: number,
    thresholds: Array<number>,
    colors: Array<string>,
    axisLabel: string
}


const CustomLineChart:FC<CustomLineChartProps> = ({data, dates, height, thresholds, colors, axisLabel}) => {

    return (
        <LineChart
            height={height}
            grid={{ horizontal: true, vertical: true }}
            series={[
                {
                    data: data,
                    label: axisLabel,
                }
            ]}
            margin={{
              top: 10,
              bottom: 20,
            }}
            yAxis={[
                {
                    colorMap: {
                        type: 'piecewise',
                        thresholds: thresholds,
                        colors: colors,
                    }
                }
            ]}
            xAxis={[
                {
                    scaleType: 'time',
                    data: dates,
                    valueFormatter: (value) => value.getHours().toString() + ':' + ((value.getMinutes().toString() === '0') ? '00' : value.getMinutes().toString()),
                }
            ]}
            slotProps={{
                legend: {
                    hidden: true
                },
            }}
        />
    )
}

export default CustomLineChart