import React, {FC} from "react";
import {Grid, Typography} from "@mui/material";
import PredictedValuesCard from "./PredictedValuesCard";
import {aqiMapping, aqiMappingBackgroundColorLight, calculateAQIFromPM10AndPM25} from "../../utils/aqiMapping";
import {SingleStationPredictionResponseType} from "../../types/predictions";


type CurrentPredictionsCardProps = {
    predictions: SingleStationPredictionResponseType | null,
    AQIEmojiIcon: any
}


const CurrentPredictionsCard:FC<CurrentPredictionsCardProps> = ({predictions, AQIEmojiIcon}) => {

    return (
        <>
            <Grid item xs={12} md={8} sx={{height: '100%'}}>

                <Grid item xs={12}>
                    <Typography className={'current-predictions-card-title'}>
                        Current
                    </Typography>
                </Grid>

                <Grid item xs={12} md={10} sx={{display: 'flex', flexDirection: 'row', columnGap: 2}}>
                    <PredictedValuesCard
                        title={'PM10'}
                        value={predictions?.predictions[0].PM10 || '--'}
                        unit={'µg/m3'}
                    />

                    <PredictedValuesCard
                        title={'PM2.5'}
                        value={predictions?.predictions[0]["PM2.5"] || '--'}
                        unit={'µg/m3'}
                    />

                    <PredictedValuesCard
                        value={predictions ? calculateAQIFromPM10AndPM25(predictions?.predictions[0].PM10, predictions?.predictions[0]["PM2.5"]) : '--'}
                        title={'AQI'}
                        unit={'ppm'}
                    />
                </Grid>
            </Grid>

            <Grid item xs={12} md={4}>
                <Grid item xs={12}>
                    <Typography className={'current-predictions-card-subtitle'}>
                        AQI
                    </Typography>
                </Grid>

                <Grid item xs={12} sx={{display: 'flex', flexDirection: 'row'}}>
                    <Grid item xs={12}
                        className={'current-predictions-card-aqi-container'}
                        sx={{
                            backgroundColor: predictions ? aqiMappingBackgroundColorLight(calculateAQIFromPM10AndPM25(predictions?.predictions[0].PM10, predictions?.predictions[0]["PM2.5"])) : 'rgba(69,159,227,0.35)',
                        }}
                    >
                            <img src={AQIEmojiIcon} style={{width: '70px', height: 'auto'}} alt={'Emoji indicating the AQI status.'}/>

                            <Typography
                                align={'center'}
                                className={'current-predictions-card-aqi-text'}
                            >
                                {predictions ? aqiMapping(calculateAQIFromPM10AndPM25(predictions?.predictions[0].PM10, predictions?.predictions[0]["PM2.5"])) : 'Unknown'}
                            </Typography>
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}

export default CurrentPredictionsCard