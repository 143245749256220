import Axios from 'axios';

export const airPollutionApi = Axios.create({
    baseURL: process.env.REACT_APP_BACKEND_API_URL,
    timeout: 420000,
    headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('jwtToken') || ''
    },
});