import {FC} from "react";
import {Grid} from "@mui/material";
import '../../styles/messages.css'


type MessageOkayProps = {
    text: string,
}


const MessageOkay:FC<MessageOkayProps> = ({text}) => {
    return (
        <Grid className={'message-container message-okay'}>
            {text}
        </Grid>
    )
}

export default MessageOkay