import {Grid, Typography} from "@mui/material";
import {Link} from "react-router-dom";
import '../../styles/footer.css'


const Footer = () => {
    return (
        <Grid
            container
            className={'footer-container'}
        >
            <Grid item xs={12} sx={{display: 'flex', flexDirection: 'row'}}>
                <Grid item xs={1} sm={0} md={1}></Grid>

                <Grid item xs={12} sm={6} md={5} sx={{display: 'flex', flexDirection: 'column'}}>
                    <Typography
                        className={'footer-title'}>
                        Pages
                    </Typography>

                    <Link to={'/map'} className={'footer-link'}>
                        Predictions map
                    </Link>

                    <Link to={'#'} className={'footer-link'}>
                        Information
                    </Link>

                    <Link to={'#'} className={'footer-link'}>
                        About us
                    </Link>

                    <Link to={'/login'} className={'footer-link'}>
                        Login / register
                    </Link>
                </Grid>

                <Grid
                    item
                    xs={12}
                    sm={6}
                    md={5}
                    className={'footer-copyright-container'}
                >
                    <Typography className={'footer-copyright-text'}>
                        ISL FERI &copy;
                    </Typography>
                    <Typography className={'footer-copyright-text'} sx={{width: 'fit-content'}}>
                        2024, Maribor
                    </Typography>
                </Grid>

                <Grid item xs={1} sm={0} md={1}></Grid>
            </Grid>

        </Grid>
    )
}

export default Footer