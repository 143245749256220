import React, {FC} from "react";
import AdminMainDashboard from "../components/admin/main/AdminMainDashboard";
import AdminPageTemplate from "../templates/AdminPageTemplate";


const AdminDashboardPage:FC = () => {
    return (
        <AdminPageTemplate children={<AdminMainDashboard />} />
    )
}

export default AdminDashboardPage