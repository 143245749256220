import React, {FC} from "react";
import AdminModelsDashboard from "../components/admin/models/AdminModelsDashboard";
import AdminPageTemplate from "../templates/AdminPageTemplate";


const AdminModelsPage:FC = () => {
    return (
        <AdminPageTemplate children={<AdminModelsDashboard />} />
    )
}

export default AdminModelsPage