import React, {FC, useContext, useEffect} from "react";
import {Navigate, Outlet, useLocation, useNavigate, useParams} from "react-router-dom";
import {AuthContext} from "../../context/AuthContext";
import {UserType} from "../../constants/users";

interface ProtectedRouteProps{
    children: JSX.Element;
}

export const ProtectedRoute:FC<ProtectedRouteProps> = ({children}) => {
    const location = useLocation();
    const navigation = useNavigate()
    const {isLoggedIn, checkToken} = useContext(AuthContext);

    useEffect(() => {
        try {
            checkToken()
        } catch (err: any) {
            if (err.response.status === 401) {
                navigation('/login')
                return
            }
        }
    }, []);

    if (isLoggedIn == null) return <Navigate to='/login' replace state={{from: location}}/>
    if (!isLoggedIn) return <Navigate to='/login' replace state={{from: location}}/>

    return children
}

export const AdminRoute:FC<ProtectedRouteProps> = ({children}) => {
    const navigation = useNavigate()
    const {userRole, checkToken} = useContext(AuthContext);

    useEffect(() => {
        try {
            checkToken()
        } catch (err: any) {
            if (err.response.status === 401) {
                navigation('/login')
                return
            }
        }
    }, []);

    if (userRole !== UserType.ADMIN) {
        return <Navigate to={'/login'}/>
    }

    return children
}