import AQIndexInformationItem from "./AQIndexInformationItem";
import AqiGoodEmojiIcon from "../../assets/aqi-emoji-good.gif";
import {aqiMapping, aqiMappingBackgroundColor} from "../../utils/aqiMapping";
import AqiModerateEmojiIcon from "../../assets/aqi-emoji-moderate.gif";
import AqiUnhealthySGEmojiIcon from "../../assets/aqi-emoji-unhealthy-sg.gif";
import AqiUnhealthyEmojiIcon from "../../assets/aqi-emoji-unhealthy.gif";
import AqiVeryUnhealthyEmojiIcon from "../../assets/aqi-emoji-very-unhealthy.gif";
import AqiHazardousEmojiIcon from "../../assets/aqi-emoji-hazardous.gif";
import {Grid} from "@mui/material";
import React from "react";


const AQIndexInformationContainer = () => {

    return (
        <Grid item xs={12} md={7} className={'aqi-index-information-container'} >
            <AQIndexInformationItem
                imageSrc={AqiGoodEmojiIcon}
                imageAlt={'Emoji for good air quality index.'}
                indexText={aqiMapping(0)}
                valuesSpan={'0-50'}
                bgColor={aqiMappingBackgroundColor(0)}
            />
            <AQIndexInformationItem
                imageSrc={AqiModerateEmojiIcon}
                imageAlt={'Emoji for moderate air quality index.'}
                indexText={aqiMapping(51)}
                valuesSpan={'51-100'}
                bgColor={aqiMappingBackgroundColor(51)}
            />
            <AQIndexInformationItem
                imageSrc={AqiUnhealthySGEmojiIcon}
                imageAlt={'Emoji for unhealthy for sensitive groups air quality index.'}
                indexText={aqiMapping(101)}
                valuesSpan={'101-150'}
                bgColor={aqiMappingBackgroundColor(101)}
            />
            <AQIndexInformationItem
                imageSrc={AqiUnhealthyEmojiIcon}
                imageAlt={'Emoji for unhealthy air quality index.'}
                indexText={aqiMapping(151)}
                valuesSpan={'151-200'}
                bgColor={aqiMappingBackgroundColor(151)}
            />
            <AQIndexInformationItem
                imageSrc={AqiVeryUnhealthyEmojiIcon}
                imageAlt={'Emoji for very unhealthy air quality index.'}
                indexText={aqiMapping(201)}
                valuesSpan={'201-300'}
                bgColor={aqiMappingBackgroundColor(201)}
            />
            <AQIndexInformationItem
                imageSrc={AqiHazardousEmojiIcon}
                imageAlt={'Emoji for hazardous air quality index.'}
                indexText={aqiMapping(301)}
                valuesSpan={'301-500'}
                bgColor={aqiMappingBackgroundColor(301)}
            />
        </Grid>
    )
}

export default AQIndexInformationContainer