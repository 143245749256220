import {FC} from "react";
import {Grid} from "@mui/material";
import '../../styles/messages.css'


type MessageErrorProps = {
    text: string,
}


const MessageError:FC<MessageErrorProps> = ({text}) => {
    return (
        <Grid className={'message-container message-error'}>
            {text}
        </Grid>
    )
}

export default MessageError