import {Box, Drawer, Grid} from "@mui/material";
import AdminSideMenu from "../components/side-menus/AdminSideMenu";
import React, {useState} from "react";
import {useResponsive} from "../hooks/responsive";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";


const AdminPageTemplate = ({children}: any) => {
    const [openMenu, setOpenMenu] = useState<boolean>(false)
    const responsive = useResponsive('up', 'md')

    return (
        <Grid container sx={{height: {xs: '100vh', sm: '100vh', md: '100vh'}, backgroundColor: '#F7F7F7', overflowY: 'hidden'}}>
            {responsive ?
                <Grid item xs={12} md={4} lg={2} sx={{height: '100%'}}>
                    <AdminSideMenu />
                </Grid>
            :
                <Grid
                    item
                    xs={12}
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'flex-start',
                        padding: 2,
                        backgroundColor: '#1F1D2B',
                        borderBottomLeftRadius: '16px',
                        borderBottomRightRadius: '16px',
                        height: '8%'
                    }}
                >
                    <IconButton
                      onClick={() => {setOpenMenu(true)}}
                    >
                        <MenuIcon sx={{color: '#fff'}} />
                    </IconButton>
                    <Drawer
                        open={openMenu}
                        onClose={() => setOpenMenu(false)}
                        PaperProps={{
                          sx: {
                            backgroundColor: 'transparent',
                            width: {
                              xs: '80%',
                              sm: '50%',
                              md: '40%',
                              lg: '20%'
                            }
                          }
                        }}>
                        <Box sx={{width: '100%', height: '100%'}}>
                          <AdminSideMenu />
                        </Box>
                    </Drawer>
                </Grid>
            }

            <Grid item xs={12} md={8} lg={10} sx={{height: '100%', paddingTop: responsive ? 0 : 2}}>
                {children}
            </Grid>
        </Grid>
    )
}

export default AdminPageTemplate