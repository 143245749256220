import {StationType} from "../types/stations";

export const originalStations: Array<StationType> = [
    {
        label: 'LJ Bežigrad',
        latitude: 46.0655,
        longitude: 14.5124,
        serial: 'E403'
    },
    {
        label: 'LJ Celovška',
        latitude: 46.064081,
        longitude: 14.494661,
        serial: 'E405'
    },
    {
        label: 'LJ Vič',
        latitude: 46.00779,
        longitude: 14.46968,
        serial: 'E404'
    },
    {
        label: 'Kranj',
        latitude: 46.23887,
        longitude: 14.35561,
        serial: 'E417'
    },
    {
        label: 'MB Titova',
        latitude: 46.557344,
        longitude: 15.65108,
        serial: 'E407'
    },
    {
        label: 'MB Vrbanski',
        latitude: 46.5678,
        longitude: 15.626,
        serial: 'E408'
    },
    {
        label: 'CE bolnica',
        latitude: 46.2366,
        longitude: 15.2259,
        serial: 'E411'
    },
    {
        label: 'CE Ljubljanska',
        latitude: 46.232328,
        longitude: 15.260037,
        serial: 'E412'
    },
    {
        label: 'Ptuj',
        latitude: 46.416667,
        longitude: 15.866667,
        serial: 'E801'
    },
    {
        label: 'MS Rakičan',
        latitude: 46.648056,
        longitude: 16.191944,
        serial: 'E409'
    },
    {
        label: 'MS Cankarjeva',
        latitude: 46.65549,
        longitude: 16.154521,
        serial: 'E410'
    },
    {
        label: 'I. Bistrica Gregorčičeva',
        latitude: 45.567906,
        longitude: 14.252692,
        serial: 'E804'
    },
    {
        label: 'NG Grčna',
        latitude: 45.955883,
        longitude: 13.643242,
        serial: 'E421'
    },
    {
        label: 'Koper',
        latitude: 45.54694,
        longitude: 13.72944,
        serial: 'E423'
    },
    {
        label: 'Trbovlje',
        latitude: 46.149706,
        longitude: 15.049064,
        serial: 'E413'
    },
    {
        label: 'Zagorje',
        latitude: 46.139819,
        longitude: 14.990422,
        serial: 'E415'
    },
    {
        label: 'Hrastnik',
        latitude: 46.147883,
        longitude: 15.086086,
        serial: 'E414'
    },
    {
        label: 'Novo mesto',
        latitude: 45.798056,
        longitude: 15.162778,
        serial: 'E418'
    },
    {
        label: 'Črna na Koroškem',
        latitude: 46.469742,
        longitude: 14.848964,
        serial: 'E802'
    },
    {
        label: 'Črnomelj',
        latitude: 45.56,
        longitude: 15.1461,
        serial: 'E803'
    },
    {
        label: 'Iskrba',
        latitude: 45.5612,
        longitude: 14.858,
        serial: 'E420'
    },
]