import {aqiNumbers} from "../types/aqi";


export const aqiMapping = (value: number) => {
    if (value >= 0 && value <= 50)
        return "Good"
    else if (value > 50 && value <= 100)
        return "Moderate"
    else if (value > 100 && value <= 150)
        return "Unhealthy for Sensitive Groups"
    else if (value > 150 && value <= 200)
        return 'Unhealthy'
    else if (value > 200 && value <= 300)
        return 'Very unhealthy'
    else if (value > 300 && value <= 500)
        return 'Hazardous'
    else
        return 'Unknown'
}

export const aqiMappingBackgroundColor = (aqi_value: number) => {
    if (aqi_value >= 0 && aqi_value <= 50)
        return "#0aa24f"
    else if (aqi_value > 50 && aqi_value <= 100)
        return "#e3a912"
    else if (aqi_value > 100 && aqi_value <= 150)
        return "#f4891e"
    else if (aqi_value > 150 && aqi_value <= 200)
        return '#e81c2b'
    else if (aqi_value > 200 && aqi_value <= 300)
        return '#6a3499'
    else if (aqi_value > 300 && aqi_value <= 500)
        return '#550000'
    else
        return '#459fe3'
}


export const aqiMappingBackgroundColorLight = (aqi_value: number) => {
    if (aqi_value >= 0 && aqi_value <= 50)
        return "rgba(10,162,79,0.35)"
    else if (aqi_value > 50 && aqi_value <= 100)
        return "rgba(234,205,42,0.35)"
    else if (aqi_value > 100 && aqi_value <= 150)
        return "rgba(244,137,30,0.35)"
    else if (aqi_value > 150 && aqi_value <= 200)
        return 'rgba(232,28,43,0.35)'
    else if (aqi_value > 200 && aqi_value <= 300)
        return 'rgba(106,52,153,0.35)'
    else if (aqi_value > 300 && aqi_value <= 500)
        return 'rgba(85,0,0,0.35)'
    else
        return 'rgba(69,159,227,0.35)'
}


export const aqiMappingColor = (aqi_value: number) => {
    if (aqi_value >= 0 && aqi_value <= 50)
        return "green"
    else if (aqi_value > 50 && aqi_value <= 100) {
        return "yellow"
    } else if (aqi_value > 100 && aqi_value <= 150)
        return "orange"
    else if (aqi_value > 150 && aqi_value <= 200)
        return 'red'
    else if (aqi_value > 200 && aqi_value <= 300)
        return 'purple'
    else if (aqi_value > 300)
        return 'maroon'
    else
        return 'blue'
}

export const calculateAQIFromPM10AndPM25 = (pm10_value: number, pm25_value: number) => {
    const pm10_breakpoints = getBreakpointsForPM10(pm10_value)
    const pm25_breakpoints = getBreakpointsForPM25(pm25_value)

    const pm10_aqi: number = calculateAQI(pm10_breakpoints)
    const pm25_aqi: number = calculateAQI(pm25_breakpoints)

    return Math.max(Math.round(pm10_aqi), Math.round(pm25_aqi))
}


const getBreakpointsForPM10 = (pm10_value: number) => {
    if(pm10_value >= 0 && pm10_value < 55) {
        const aqi: aqiNumbers = {
            pm_value: pm10_value,
            concentration_lower: 0,
            concentration_upper: 55,
            aqi_lower: 0,
            aqi_upper: 50,
        }
        return aqi
    } else if (pm10_value >= 55 && pm10_value < 155) {
        const aqi: aqiNumbers = {
            pm_value: pm10_value,
            concentration_lower: 55,
            concentration_upper: 155,
            aqi_lower: 51,
            aqi_upper: 100,
        }
        return aqi
    } else if (pm10_value >= 155 && pm10_value < 255) {
        const aqi: aqiNumbers = {
            pm_value: pm10_value,
            concentration_lower: 155,
            concentration_upper: 255,
            aqi_lower: 101,
            aqi_upper: 150,
        }
        return aqi
    } else if (pm10_value >= 255 && pm10_value < 355) {
        const aqi: aqiNumbers = {
            pm_value: pm10_value,
            concentration_lower: 255,
            concentration_upper: 355,
            aqi_lower: 151,
            aqi_upper: 200,
        }
        return aqi
    } else if (pm10_value >= 355 && pm10_value < 425) {
        const aqi: aqiNumbers = {
            pm_value: pm10_value,
            concentration_lower: 355,
            concentration_upper: 425,
            aqi_lower: 201,
            aqi_upper: 300,
        }
        return aqi
    } else {
        const aqi: aqiNumbers = {
            pm_value: pm10_value,
            concentration_lower: 425,
            concentration_upper: 604,
            aqi_lower: 301,
            aqi_upper: 500,
        }
        return aqi
    }
}

const getBreakpointsForPM25 = (pm25_value: number) => {
    if(pm25_value >= 0 && pm25_value <= 12) {
        const aqi: aqiNumbers = {
            pm_value: pm25_value,
            concentration_lower: 0,
            concentration_upper: 12,
            aqi_lower: 0,
            aqi_upper: 50,
        }
        return aqi
    } else if (pm25_value > 12 && pm25_value < 35.5) {
        const aqi: aqiNumbers = {
            pm_value: pm25_value,
            concentration_lower: 12,
            concentration_upper: 35.5,
            aqi_lower: 51,
            aqi_upper: 100,
        }
        return aqi
    } else if (pm25_value >= 35.5 && pm25_value < 55.5) {
        const aqi: aqiNumbers = {
            pm_value: pm25_value,
            concentration_lower: 35.5,
            concentration_upper: 55.5,
            aqi_lower: 101,
            aqi_upper: 150,
        }
        return aqi
    } else if (pm25_value >= 55.5 && pm25_value < 150.5) {
        const aqi: aqiNumbers = {
            pm_value: pm25_value,
            concentration_lower: 55.5,
            concentration_upper: 150.5,
            aqi_lower: 151,
            aqi_upper: 200,
        }
        return aqi
    } else if (pm25_value >= 150.5 && pm25_value < 250.5) {
        const aqi: aqiNumbers = {
            pm_value: pm25_value,
            concentration_lower: 150.5,
            concentration_upper: 250.5,
            aqi_lower: 201,
            aqi_upper: 300,
        }
        return aqi
    } else {
        const aqi: aqiNumbers = {
            pm_value: pm25_value,
            concentration_lower: 250.5,
            concentration_upper: 500.4,
            aqi_lower: 301,
            aqi_upper: 500,
        }
        return aqi
    }
}

const calculateAQI = (values: aqiNumbers) => {
    const aqi =
        ((values.aqi_upper - values.aqi_lower) / (values.concentration_upper - values.concentration_lower))
        * (values.pm_value - values.concentration_lower) + values.aqi_lower

    return aqi
}