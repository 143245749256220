import React, {FC, useContext, useEffect, useState} from 'react'
import {MapContainer, Marker, Popup, TileLayer, Tooltip} from "react-leaflet";
import 'leaflet/dist/leaflet.css'
import '../../styles/station-map.css'
//@ts-ignore
import L from 'leaflet'
import {StationContext} from "../../context/StationsContext";
import {aqiMappingColor, calculateAQIFromPM10AndPM25} from "../../utils/aqiMapping";
import PredictionsCard from "./PredictionsCard";
import {airPollutionApi} from "../../utils/axios";
import {PredictionType, SingleStationPredictionResponseType} from "../../types/predictions";
import {StationType} from "../../types/stations";
import BaseCircularProgress from "../common/BaseCircularProgress";

type StationMapProps = {
}


const StationMap: FC<StationMapProps> = ({}) => {
    const attribution = '&copy; <a href="https://www.stadiamaps.com/" target="_blank">Stadia Maps</a> &copy; <a href="https://openmaptiles.org/" target="_blank">OpenMapTiles</a> &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
    const url = 'https://tiles.stadiamaps.com/tiles/alidade_smooth/{z}/{x}/{y}{r}.png'
    const [station, setStation] = useState<StationType | null>(null)
    const { stations, currentData} = useContext(StationContext)
    const [predictions, setPredictions] = useState<SingleStationPredictionResponseType | null>(null)

    const onClickOnMarker = (event: any) => {
        const { lat, lng } = event.latlng

        const found = stations.find((stand) => (stand.latitude === lat && stand.longitude === lng))

        if(found)
            setStation(found)
    }

    const getMarkerIcon = (station: string) => {
        let currentIcon = new L.Icon({
          iconUrl: require('../../assets/marker-icon-orange.png'),
          iconSize: [40, 40], // Size of the icon
          iconAnchor: [16, 32], // Point of the icon which will correspond to marker's location
          popupAnchor: [0, -32] // Point from which the popup should open relative to the iconAnchor
        });

        if (currentData) {
            const currentStationData = currentData.predictions.find((currentDataItem: PredictionType) => currentDataItem.station === station)
            if (currentStationData) {
                const currentAqi = aqiMappingColor(calculateAQIFromPM10AndPM25(currentStationData.pred_pm10, currentStationData.pred_pm25))

                currentIcon = new L.Icon({
                  iconUrl: require(`../../assets/marker-icon-${currentAqi}.png`),
                  iconSize: [40, 40], // Size of the icon
                  iconAnchor: [16, 32], // Point of the icon which will correspond to marker's location
                  popupAnchor: [0, -32] // Point from which the popup should open relative to the iconAnchor
                });
            }
        }

        return currentIcon
    }

    const fetchStationPredictions = async () => {

        const response = await airPollutionApi.post('/predict/predict', {station: station?.serial})

        setPredictions(response.data)
    }


    useEffect(() => {
        if(station) {
            setPredictions(null)
            fetchStationPredictions()
        }
    }, [station])


    return (
        <div style={{height: '100vh'}}>
            {/*@ts-ignore*/}
            <MapContainer key={'map-container'} center={[46.155, 15.05333]} zoom={9} minZoom={5} maxZoom={30} scrollWheelZoom={true} zoomControl={false}>
                {/*@ts-ignore*/}
                <TileLayer key={'map-tile-layer'} attribution={attribution} url={url}/>

                {stations?.map((stationContextItem: StationType, index: number) => {
                    return (
                        <div key={stationContextItem.serial + stationContextItem.label + index + '-0'}>
                            {/*@ts-ignore*/}
                            <Marker key={stationContextItem.serial + stationContextItem.label + index + '-1'} position={[stationContextItem.latitude,stationContextItem.longitude]} icon={getMarkerIcon(stationContextItem.serial)} eventHandlers={{click: (e) => onClickOnMarker(e)}}>
                                <Tooltip key={stationContextItem.serial + stationContextItem.label + index + '-2'}>
                                    {stationContextItem.label}
                                </Tooltip>
                                <Popup key={stationContextItem.serial + stationContextItem.label + index + '-3'}>
                                    {predictions ?
                                        <PredictionsCard key={stationContextItem.serial + stationContextItem.label + index + '-4'}
                                                         station={station}
                                                         predictions={predictions}
                                        />
                                    :
                                        <BaseCircularProgress />
                                    }
                                </Popup>
                            </Marker>
                        </div>
                    )
                })}

            </MapContainer>
        </div>
    )
}

export default StationMap