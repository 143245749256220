import React, {FC} from "react";
import {Grid, Typography} from "@mui/material";
import SatelliteAltIcon from "@mui/icons-material/SatelliteAlt";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Divider from "@mui/material/Divider";
import {useResponsive} from "../../hooks/responsive";
import {StationType} from "../../types/stations";
import {ForecastGraphDataType, SingleStationPredictionResponseType} from "../../types/predictions";
import CurrentPredictionsCard from "./CurrentPredictionsCard";
import ForecastPredictionsCard from "./ForecastPredictionsCard";


type StationCardProps = {
    station: StationType | null,
    predictions: SingleStationPredictionResponseType | null,
    AQIEmojiIcon: any,
    graphData: ForecastGraphDataType | null,
    setStationFromProps: (value: StationType | null) => void,
    setGraphDataFromProps: (value: ForecastGraphDataType | null) => void,
    setAQIEmojiIconFromProps: () => void
}

const StationCard:FC<StationCardProps> = (
    {
        station,
        predictions,
        AQIEmojiIcon,
        graphData,
        setStationFromProps,
        setGraphDataFromProps,
        setAQIEmojiIconFromProps
    }) => {

    const isResponsive = useResponsive('down', 'md')

    return (
        <>
            <Grid item xs={12}
                  sx={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', marginBottom: '15px'}}>
                <Grid sx={{display: 'flex', flexDirection: 'row'}}>
                    <SatelliteAltIcon sx={{marginRight: 2, fontSize: '42px'}}/>
                    <Typography sx={{fontSize: '1.85rem', letterSpacing: '2px', fontWeight: 800, flexWrap: 'wrap', display: 'flex'}}>
                        {station?.label}
                    </Typography>
                </Grid>
                <IconButton onClick={() => {setStationFromProps(null); setGraphDataFromProps(null); setAQIEmojiIconFromProps()}}>
                    <CloseIcon />
                </IconButton>
            </Grid>

            <Divider/>

            <Grid container
                sx={{
                    marginBottom: '20px',
                    marginTop: '10px',
                    display: 'flex',
                    flexDirection: isResponsive ? 'column': 'row',
                    height: '100%'
                }}
            >
                <CurrentPredictionsCard predictions={predictions} AQIEmojiIcon={AQIEmojiIcon} />
            </Grid>

            <Divider/>

            <Grid container sx={{marginTop: '15px'}}>
                <ForecastPredictionsCard graphData={graphData} />
            </Grid>
        </>
    )
}

export default StationCard