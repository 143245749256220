import React, {FC} from "react";
import {Grid} from "@mui/material";
import {useResponsive} from "../../hooks/responsive";
import '../../styles/iframe-container.css'


type IFrameContainerProps = {
    siteURL: string,
    title: string
}


const IFrameContainer:FC<IFrameContainerProps> = ({siteURL, title}) => {
    const isMd = useResponsive('up', 'md')

    return (
        <Grid container
              className={'iframe-container'}
              sx={{
                padding: isMd ? 6 : 2,
                rowGap: 7
              }}
        >
            <iframe
                width={'100%'}
                height={'100%'}
                title={title}
                src={siteURL}
            ></iframe>
        </Grid>
    )
}

export default IFrameContainer