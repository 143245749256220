import React, {FC} from "react";
import StationMap from "../components/home/StationMap";
import BasePageTemplate from "../templates/BasePageTemplate";


const HomePage:FC = () => {
    return (
        <BasePageTemplate children={<StationMap />} />
    )
}

export default HomePage