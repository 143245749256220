import {Typography} from "@mui/material";
import React, {FC} from "react";
import '../../styles/landing-page.css'

type TitleWithDescriptionProps = {
    title: string,
    description: string
}


const TitleWithDescription:FC<TitleWithDescriptionProps> = ({title, description}) => {

    return (
        <>
            <Typography className={'title-with-description-title'}>
                {title}
            </Typography>

            <Typography className={'title-with-description-description'}>
                {description}
            </Typography>
        </>
    )
}

export default TitleWithDescription