import React, {FC, useContext, useState} from "react";
import { Grid, ListItemIcon, Menu, MenuItem, Typography} from "@mui/material";
import {useLocation, useNavigate} from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import Tooltip from '@mui/material/Tooltip';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import Divider from "@mui/material/Divider";
import {Login, Logout} from "@mui/icons-material";
import DashboardIcon from "@mui/icons-material/Dashboard";
import ModelTrainingIcon from "@mui/icons-material/ModelTraining";
import AssessmentIcon from "@mui/icons-material/Assessment";
import {AuthContext} from "../../context/AuthContext";
import {UserType} from "../../constants/users";
import MapIcon from "@mui/icons-material/Map";
import ManageAccountsRoundedIcon from "@mui/icons-material/ManageAccountsRounded";
import '../../styles/header.css'


const Header:FC<any> = () => {
    const navigation = useNavigate()
    const routePath = useLocation()
    const {isLoggedIn, userRole, logoutUser} = useContext(AuthContext)
    const [profileMenuOpen, setProfileMenuOpen] = useState<boolean>(false)
    const [profileMenuAnchorElement, setProfileMenuAnchorElement] = useState<HTMLElement | null>(null)

    const handleProfileClick = (event: React.MouseEvent<HTMLElement>) => {
        setProfileMenuAnchorElement(event.currentTarget)
        setProfileMenuOpen(true)
    }

    const handleProfileMenuClose = () => {
        setProfileMenuOpen(false)
        setProfileMenuAnchorElement(null)
    }

    return (
        <Grid sx={{backgroundColor: routePath.pathname === '/' ? 'rgba(0,180,255,0.3)' : undefined}}>
            <Grid container
                sx={{
                    display: 'flex',
                    alignContent: 'center',
                    padding: '12px 5%',
                    backgroundColor: routePath.pathname === '/' ? undefined : '#0F123F',
                    color: routePath.pathname === '/' ? '#000' : '#fff',
                    borderBottomRightRadius: '12px',
                    borderBottomLeftRadius: '12px',
                }}
            >
                <Grid item xs={6}>
                    <Typography
                        className={'header-brand-title'}
                        onClick={() => {
                            navigation('/')
                        }}
                    >
                        Airly
                    </Typography>
                </Grid>
                <Grid item xs={6}
                      className={'header-button-container'}>
                    {isLoggedIn ?
                        <Grid>
                            <Tooltip title="Admin dashboard">
                                <IconButton
                                    onClick={handleProfileClick}
                                    size="small"
                                    sx={{ ml: 2 }}
                                    aria-controls={isLoggedIn ? 'account-menu' : undefined}
                                    aria-haspopup="true"
                                    aria-expanded={isLoggedIn ? 'true' : undefined}
                                >
                                    <AdminPanelSettingsIcon sx={{color: routePath.pathname === '/' ? '#000' : '#fff', fontSize: '1.85rem'}} />
                                </IconButton>
                            </Tooltip>

                            <Menu
                                anchorEl={profileMenuAnchorElement}
                                id="account-menu"
                                open={profileMenuOpen}
                                onClose={handleProfileMenuClose}
                                onClick={handleProfileMenuClose}
                                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                            >
                                <MenuItem
                                    onClick={() => {navigation('/map')}}
                                    className={'header-admin-button-container'}
                                >
                                    <ListItemIcon>
                                        <MapIcon sx={{color: '#000'}} />
                                    </ListItemIcon>
                                    Map

                                </MenuItem>

                                <MenuItem
                                    onClick={() => {navigation('/dashboard')}}
                                    className={'header-admin-button-container'}
                                >
                                    <ListItemIcon>
                                        <DashboardIcon sx={{color: '#000'}} />
                                    </ListItemIcon>
                                    Dashboard

                                </MenuItem>

                                {userRole === UserType.ADMIN &&
                                    <div>
                                        <Divider />

                                        <MenuItem
                                            onClick={() => {navigation('/models')}}
                                            className={'header-admin-button-container'}
                                        >
                                            <ListItemIcon>
                                                <ModelTrainingIcon sx={{color: '#000'}} />
                                            </ListItemIcon>
                                            Models

                                        </MenuItem>
                                        <MenuItem
                                            onClick={() => {navigation('/validation')}}
                                            className={'header-admin-button-container'}
                                        >
                                            <ListItemIcon>
                                                <AssessmentIcon sx={{color: '#000'}} />
                                            </ListItemIcon>
                                            Validation reports

                                        </MenuItem>
                                        <MenuItem
                                            onClick={() => {navigation('/testing')}}
                                            className={'header-admin-button-container'}
                                        >
                                            <ListItemIcon>
                                                <AssessmentIcon sx={{color: '#000'}} />
                                            </ListItemIcon>
                                            Stability reports

                                        </MenuItem>

                                        <MenuItem
                                            onClick={() => {navigation('/users')}}
                                            className={'header-admin-button-container'}
                                        >
                                            <ListItemIcon>
                                                <ManageAccountsRoundedIcon sx={{color: '#000'}} />
                                            </ListItemIcon>
                                            Users

                                        </MenuItem>
                                    </div>
                                }
                                <Divider />
                                <MenuItem
                                    onClick={() => {logoutUser(); handleProfileMenuClose()}}
                                    className={'header-admin-button-container'}
                                >
                                    <ListItemIcon>
                                        <Logout sx={{color: '#000'}} />
                                    </ListItemIcon>
                                    Logout

                                </MenuItem>
                              </Menu>
                        </Grid>
                        :
                        <div className={'header-login-button-container'}
                           onClick={() => {
                               navigation('/login')
                           }}>
                            <Login sx={{color: routePath.pathname === '/' ? '#000' : '#fff', fontSize: '1.85rem'}} />
                        </div>
                    }
                </Grid>
            </Grid>
        </Grid>
    )
}

export default Header