import {Button} from "@mui/material";
import React, {FC} from "react";
import {useLocation, useNavigate} from "react-router-dom";


type AdminSideButtonProps = {
    navigationLink: string,
    icon: any,
    text: string
}

const AdminSideButton:FC<AdminSideButtonProps> = ({navigationLink, icon, text}) => {
    const navigation = useNavigate()
    const routePath = useLocation()

    return (
        <Button className={`${(routePath.pathname === navigationLink) ? 'admin-side-button-active': 'admin-side-button'}`}
                sx={{display: 'flex', justifyContent: 'flex-start'}}
            onClick={() => {navigation(navigationLink)}}
            >
            {icon}
            <div>{text}</div>
        </Button>
    )
}

export default AdminSideButton