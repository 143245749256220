import React, {FC} from "react";
import {Grid, Typography} from "@mui/material";
import {LineChart} from "@mui/x-charts";


type ModelValuesGraphProps = {
    dates: Array<Date>,
    pro_data: Array<number | null>,
    champ_data: Array<number | null>,
    actual_data: Array<number | null>
    graphWidth: number,
    title: string
}

const ModelValuesGraph:FC<ModelValuesGraphProps> = ({
        dates,
        pro_data,
        champ_data,
        actual_data,
        graphWidth,
        title
    }) => {

    return (
        <Grid item xs={12} md={12}
            sx={{
                backgroundColor: '#fff',
                borderRadius: '20px',
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column',
                alignItems: 'center',
                border: '1px solid #E6EDFF'
            }}
        >
            <Typography sx={{textAlign: 'center', padding: 1, fontWeight: 600}}>
                {title}
            </Typography>
            <LineChart
                xAxis={[{
                    data: dates || [],
                    scaleType: 'utc'
                }]}
                series={[
                    {
                        label: 'Production',
                        data: pro_data,
                        color: '#0F123F'
                    },
                    {
                        label: 'Champion',
                        data: champ_data,
                        color: '#ffa800'
                    },
                    {
                        label: 'Actual',
                        data: actual_data,
                        color: '#868686'
                    }
                ]}
                width={graphWidth}
                height={300}
                slotProps={{
                    legend: {
                        position: {vertical: 'top', horizontal: 'middle'}
                    }
                }}
            />
        </Grid>
    )
}

export default ModelValuesGraph