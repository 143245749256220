import React from "react";
import {Box, LinearProgress} from "@mui/material";


const BaseProgressBar = () => {

    return (
        <Box sx={{width: '100%', marginTop: 5}}>
            <LinearProgress sx={{
                backgroundColor: '#0F123F',
                '& .MuiLinearProgress-bar': {
                    backgroundColor: '#ffa800'
                }}}
            />
        </Box>
    )
}

export default BaseProgressBar