import React, { FC } from "react";
import { Grid } from "@mui/material";
import {aqiMappingBackgroundColor, calculateAQIFromPM10AndPM25} from "../../utils/aqiMapping";
import {SingleStationPredictionResponseType, SingleStationPredictionType} from "../../types/predictions";
import {StationType} from "../../types/stations";


type PredictionsCardProps = {
    station: StationType | null,
    predictions: SingleStationPredictionResponseType | null
}

const PredictionsCard:FC<PredictionsCardProps> = ({station, predictions}) => {

    return (
        <Grid sx={{minWidth: '250px', margin: 0, padding: 2}}>
            <Grid container>
                <Grid item xs={12} sx={{display: 'flex', flexDirection: 'row', marginBottom: 1}}>
                    <Grid item xs={3}>
                    </Grid>
                    <Grid item xs={3}>
                    </Grid>
                    <Grid item xs={3}>
                        PM10
                    </Grid>
                    <Grid item xs={3}>
                        PM2.5
                    </Grid>
                </Grid>

                {predictions && predictions.predictions.map((predictionItem: SingleStationPredictionType, index: number) => {
                    return (
                        <Grid key={predictionItem.date + index} item xs={12} sx={{display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}}>
                            <Grid item xs={3}>
                                {`${new Date(predictionItem.date).getHours()}:00`}
                            </Grid>
                            <Grid item xs={3} sx={{
                                display: 'flex',
                                justifyContent: 'flex-start',
                                flexDirection: 'column',
                                alignItems: 'center'
                            }}>
                                {index != 0 &&
                                    <div style={{
                                        background: aqiMappingBackgroundColor(calculateAQIFromPM10AndPM25(predictionItem.PM10, predictionItem["PM2.5"])),
                                        width: '5px',
                                        height: '10px',
                                    }}></div>
                                }
                                <div style={{
                                    width: '25px',
                                    height: '25px',
                                    borderRadius: '50%',
                                    backgroundColor: aqiMappingBackgroundColor(calculateAQIFromPM10AndPM25(predictionItem.PM10, predictionItem["PM2.5"]))
                                }}></div>
                                {index != predictions.predictions.length - 1 &&
                                    <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
                                        <div style={{
                                            background:
                                                `linear-gradient(to bottom, 
                                                            ${aqiMappingBackgroundColor(calculateAQIFromPM10AndPM25(predictionItem.PM10, predictionItem["PM2.5"]))} 0%, 
                                                            ${aqiMappingBackgroundColor(calculateAQIFromPM10AndPM25(predictionItem.PM10, predictionItem["PM2.5"]))} 65%, 
                                                            ${(index + 1) < predictions.predictions.length ?
                                                    aqiMappingBackgroundColor(calculateAQIFromPM10AndPM25(predictions.predictions[index + 1].PM10, predictions.predictions[index + 1]["PM2.5"]))
                                                    :
                                                    aqiMappingBackgroundColor(calculateAQIFromPM10AndPM25(predictionItem.PM10, predictionItem["PM2.5"]))} 95%, 
                                                            ${(index + 1) < predictions.predictions.length ?
                                                    aqiMappingBackgroundColor(calculateAQIFromPM10AndPM25(predictions.predictions[index + 1].PM10, predictions.predictions[index + 1]["PM2.5"]))
                                                    :
                                                    aqiMappingBackgroundColor(calculateAQIFromPM10AndPM25(predictionItem.PM10, predictionItem["PM2.5"]))} 100%)`,
                                            width: '5px',
                                            height: '10px'
                                        }}></div>
                                    </div>}
                            </Grid>
                            <Grid item xs={3}>
                                {predictionItem.PM10}
                            </Grid>
                            <Grid item xs={3}>
                                {predictionItem["PM2.5"]}
                            </Grid>
                        </Grid>
                    )
                })}
            </Grid>
        </Grid>
    )
}

export default PredictionsCard