import React, {ChangeEvent, ChangeEventHandler, FC, useContext, useEffect, useState} from "react";
import {
    Box, Button, DialogActions,
    FormControl,
    Grid,
    InputLabel, LinearProgress,
    MenuItem, Modal,
    Paper, Select,
    SelectChangeEvent,
    Table, TableBody, TableCell,
    TableContainer,
    TableHead, TableRow, TextField, Typography
} from "@mui/material";
import {StyledSelect} from "../main/AdminMainDashboard";
import '../../../styles/admin-dashboard.css'
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import {StationContext} from "../../../context/StationsContext";
import {airPollutionApi} from "../../../utils/axios";
import WarningIcon from '@mui/icons-material/Warning';
import LayersIcon from '@mui/icons-material/Layers';
import AddIcon from '@mui/icons-material/Add';
import ClearIcon from '@mui/icons-material/Clear';
import {useResponsive} from "../../../hooks/responsive";
import DeleteModelModal from "./DeleteModelModal";
import {ModelVersion} from "../../../types/models";
import {StationType} from "../../../types/stations";
import BaseProgressBar from "../../common/BaseProgressBar";


type AdminModelsDashboardProps = {}


const AdminModelsDashboard:FC<AdminModelsDashboardProps> = () => {
    const { stations } = useContext(StationContext)
    const [station, setStation] = useState<string>('--')
    const [particles, setParticles] = useState<string>('PM10')
    const [models, setModels] = useState<Array<ModelVersion> | null>(null)
    const [editModalOpen, setEditModalOpen] = useState<boolean>(false)
    const [currentModel, setCurrentModel] = useState<ModelVersion | null>(null)
    const [newAlias, setNewAlias] = useState<string>('')
    const [currentModelAlias, setCurrentModelAlias] = useState<Array<string>>([])
    const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false)
    const isMd = useResponsive('up', 'md')
    const isLg = useResponsive('up', 'lg')

    const handleStationChange = (event: SelectChangeEvent<unknown>) => {
        setStation(event.target.value as string);
    };

    const handleParticlesChange = (event: SelectChangeEvent<unknown>) => {
        setParticles(event.target.value as string)
    }

    const fetchModelVersions = async () => {
        try {
            const modelVersionsResponse = await airPollutionApi.post('/models/get-model-versions',
                {'station': station, 'particles': particles}
            )

            const modelVersions = modelVersionsResponse.data.models

            setModels(modelVersions)
        } catch (err: any) {
            console.log('err', err)
        }

    }

    useEffect(() => {
        setModels(null)
        if (station !== '--') {
            fetchModelVersions()
        }
    }, [particles, station])

    useEffect(() => {
        if (currentModel)
            setCurrentModelAlias(currentModel.alias)

    }, [currentModel])

    const handleClose = () => {
        setCurrentModel(null)
        setEditModalOpen(false)
        setDeleteModalOpen(false)
    }

    const handleAliasNameChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setNewAlias(event.currentTarget.value)
    }

    const handleAddNewAlias = () => {
        if(!currentModelAlias.includes(newAlias) && newAlias !== '') {
            setCurrentModelAlias((prevState: Array<string>) => ([...prevState, newAlias]))
            setNewAlias('')
        }
    }

    const handleCancelNewAliases = () => {
        setCurrentModelAlias([])
        setCurrentModel(null)
        setNewAlias('')
        setEditModalOpen(false)
    }

    const handleCancelDeleteModelVersion = () => {
        setCurrentModel(null)
        setDeleteModalOpen(false)
    }

    const handleDeleteCurrentAlias = (name: string) => {
        if (currentModelAlias.includes(name)) {
            const tempModelAliasArray = currentModelAlias.filter((aliasName) => aliasName !== name)
            setCurrentModelAlias(tempModelAliasArray)
        }
    }

    const handleNewAliasesConfirmChange = async () => {
        const newAliases = currentModelAlias
        const oldAliases = currentModel?.alias || []

        const tempParticles = particles.replace('.', '')

        try {
            const result = await airPollutionApi.post('/models/change-model-versions',
            {station, particles: tempParticles, version: currentModel?.version, new_aliases: newAliases, old_alias: oldAliases})

            if (result.data.response == 'Change successful') {
                fetchModelVersions()
                handleCancelNewAliases()
            } else {
                // TODO: error handling, notifying user of error
            }
        } catch (err: any) {
            console.log('err', err)
        }

    }

    const handleDeleteModelVersion = async () => {
        try {
            const response = await airPollutionApi.post('/models/delete-model-version', {station, particles, version: currentModel?.version})

            if (response.data.response === 'Successfully deleted model.') {
                fetchModelVersions()
                setCurrentModel(null)
                setDeleteModalOpen(false)
            }
        } catch (err: any) {
            console.log('err', err)
        }
    }

    const handleSelectAliasChange = (value: React.ChangeEvent<HTMLInputElement>) => {
        setNewAlias(value.target.value as string)
    };

    return (
        <Grid container sx={{
            padding: isLg ? 6 : 2,
            rowGap: 7,
            display: 'flex',
            flexDirection: 'row',
            maxHeight: '100%',
            overflowY: (station && particles) ? 'scroll' : 'hidden',
            backgroundColor: '#F7F7F7',
            alignSelf: 'flex-start'
        }}>
            <Grid item xs={12} sx={{
                display: 'flex',
                flexDirection: isMd ? 'row' : 'column',
                justifyContent: 'center',
                alignItems: 'center',
                marginRight: {
                    xs: '5%',
                    md: '20%'
                },
                marginLeft: {
                    xs: '5%',
                    md: '20%'
                },
                columnGap: 3,
                rowGap: 2
            }}>
                <Grid item xs={12} md={2} sx={isMd ? {} : {width: '100%'}}>
                    <FormControl fullWidth sx={{borderColor: '#0F123F'}}>
                        <InputLabel id="admin-particles-select-label" sx={{color: '#0F123F'}}>
                            Particles
                        </InputLabel>
                        <StyledSelect
                            labelId="admin-particles-select-label"
                            id="admin-particles-select"
                            value={particles}
                            label="Particles"
                            onChange={handleParticlesChange}
                            sx={{
                                backgroundColor: '#fff',
                                borderColor: '#0F123F',
                                fontWeight: 700,
                                textTransform: 'uppercase',
                                letterSpacing: '2px'
                            }}
                            fullWidth={!isMd}
                            MenuProps={{ PaperProps: { sx: {maxHeight: 300 }}}}
                        >
                            <MenuItem className={'admin-station-menu-item'} value={'PM10'}>PM10</MenuItem>
                            <MenuItem className={'admin-station-menu-item'} value={'PM25'}>PM2.5</MenuItem>
                        </StyledSelect>
                    </FormControl>
                </Grid>

                <Grid item xs={12} md={10} sx={isMd ? {} : {width: '100%'}}>
                    <FormControl fullWidth sx={{borderColor: '#0F123F'}}>
                        <InputLabel id="admin-station-select-label" sx={{color: '#0F123F'}}>
                            Station
                        </InputLabel>
                        <StyledSelect
                            labelId="admin-station-select-label"
                            id="admin-station-select"
                            value={station}
                            label="Station"
                            onChange={handleStationChange}
                            sx={{
                                backgroundColor: '#fff',
                                borderColor: '#0F123F',
                                fontWeight: 700,
                                textTransform: 'uppercase',
                                letterSpacing: '2px'
                            }}
                            fullWidth={!isMd}
                            MenuProps={{ PaperProps: { sx: {maxHeight: 300 }}}}
                        >
                            <MenuItem className={'admin-station-menu-item'} value={'--'}>Choose a station</MenuItem>
                            {stations.map((stationItem: StationType) => {
                                return (
                                    <MenuItem key={`${stationItem.serial}-menu-item`} className={'admin-station-menu-item'} value={stationItem.serial}>{stationItem.label}</MenuItem>
                                )
                            })}
                        </StyledSelect>
                    </FormControl>
                </Grid>
            </Grid>

            <Grid item xs={12} sx={{
                paddingLeft: {
                    sm: 0,
                    md: 2,
                    lg: 10
                },
                paddingRight: {
                    sm: 0,
                    md: 2,
                    lg: 10
                },
                paddingBottom: isMd ? 2 : 12
            }}>
                {station !== '--' ?
                    models ?
                        <TableContainer component={Paper} sx={{ borderRadius: '16px' }}>
                            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell className={'admin-model-table-header-cell'}></TableCell>
                                        <TableCell className={'admin-model-table-header-cell'}>Version</TableCell>
                                        <TableCell className={'admin-model-table-header-cell'} align={'center'}>Alias</TableCell>
                                        <TableCell className={'admin-model-table-header-cell'} align={'center'}>Created</TableCell>
                                        <TableCell className={'admin-model-table-header-cell'} align={'center'}>EVS</TableCell>
                                        <TableCell className={'admin-model-table-header-cell'} align={'center'}>MAE</TableCell>
                                        <TableCell className={'admin-model-table-header-cell'} align={'center'}>MSE</TableCell>
                                        <TableCell className={'admin-model-table-header-cell'} align={'center'}>Status</TableCell>
                                        <TableCell className={'admin-model-table-header-cell'} align={'right'}></TableCell>
                                    </TableRow>
                                </TableHead>

                                <TableBody>
                                    {models.map((modelItem: ModelVersion) => {
                                        return (
                                            <TableRow key={station + modelItem.version}>
                                                <TableCell>
                                                    <div className={'model-icon-container'}>
                                                        <LayersIcon />
                                                    </div>
                                                </TableCell>
                                                <TableCell>
                                                    Version {modelItem.version}
                                                </TableCell>
                                                <TableCell align={'center'}>
                                                    <div className={'model-alias-container'}>
                                                        {modelItem.alias.map((modelAlias: string) => {
                                                        return (
                                                            <div key={station + '-' + modelAlias + '-1'} className={'model-alias-container-text'}>
                                                                @ {modelAlias}
                                                            </div>
                                                        )
                                                    })}
                                                    </div>
                                                </TableCell>
                                                <TableCell align={'center'}>
                                                    {new Date(modelItem.created).toDateString()}
                                                </TableCell>
                                                <TableCell align={'center'}>
                                                    {modelItem.evs ? modelItem.evs.toFixed(5) : '--'}
                                                </TableCell>
                                                <TableCell align={'center'}>
                                                    {modelItem.mae ? modelItem.mae.toFixed(5) : '--'}
                                                </TableCell>
                                                <TableCell align={'center'}>
                                                    {modelItem.mse ? modelItem.mse.toFixed(5) : '--'}
                                                </TableCell>
                                                <TableCell align={'center'}>
                                                    <div className={'model-status-container'}>
                                                        {modelItem.alias.length !== 0 ?
                                                            <div className={'model-status-container-active'}>
                                                                Active
                                                            </div>
                                                            :
                                                            <div className={'model-status-container-inactive'}>
                                                                Inactive
                                                            </div>
                                                        }
                                                    </div>

                                                </TableCell>
                                                <TableCell align={'right'}>
                                                    <IconButton
                                                        className={'admin-models-management-button'}
                                                        onClick={() => {setCurrentModel(modelItem); setEditModalOpen(true)}}
                                                        sx={{
                                                            color: '#1976d2',
                                                            borderColor: '#1976d2',
                                                            padding: '5px',
                                                            width: 'fit-content'
                                                        }}
                                                    >
                                                        <EditIcon />
                                                    </IconButton>
                                                    <IconButton
                                                        className={'admin-models-management-button'}
                                                        onClick={() => {setCurrentModel(modelItem); setDeleteModalOpen(true)}}
                                                        sx={{
                                                            color: '#e52626',
                                                            padding: '5px',
                                                            width: 'fit-content',
                                                            marginLeft: 1
                                                        }}
                                                    >
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </TableCell>
                                            </TableRow>
                                        )
                                    })}
                                </TableBody>

                            </Table>
                        </TableContainer>

                    :
                        <BaseProgressBar />

                :
                    <Grid item xs={12} sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%'}}>
                        <div className={'warning-message-container'}>
                            <WarningIcon fontSize='large' sx={{marginRight: 2, color: '#ffa800'}} />
                            Please choose a station
                        </div>
                    </Grid>
                }
            </Grid>

            <Modal
                open={editModalOpen}
                onClose={handleClose}
                aria-labelledby="edit-alias-modal-title"
                aria-describedby="edit-alias-modal-description"
                sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}
            >
                <Paper sx={{display: 'flex', padding: 2, paddingLeft: 4, paddingRight: 4, minWidth: '40%', flexDirection: 'column'}}>
                    <Typography id="edit-alias-modal-title" variant="h6" component="h2" sx={{fontWeight: 800}}>
                        Edit model aliases
                    </Typography>
                    <Box>
                        <Typography id="edit-alias-modal-description" sx={{ mt: 2, fontWeight: 700 }}>
                            Current aliases:
                        </Typography>
                        <Box sx={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', gap: 2, flexWrap: 'wrap', marginBottom: 4, marginTop: 1}}>
                            {
                                (currentModelAlias.length > 0) ? currentModelAlias.map((aliasName: string) => {
                                return (
                                    <div key={station + '-' + aliasName} className={'editing-model-alias-container'}>
                                        <div className={'editing-model-alias-name'}>
                                            {aliasName}
                                            <IconButton sx={{
                                                padding: 0,
                                                marginLeft: '10px',
                                                minWidth: 'fit-content',
                                                lineHeight: 'normal',
                                                borderRadius: '8px',
                                                backgroundColor: 'rgb(255,67,67)',
                                                color: '#fff'
                                            }}
                                                onClick={() => {handleDeleteCurrentAlias(aliasName)}}
                                            >
                                                <ClearIcon />
                                            </IconButton>
                                        </div>
                                    </div>
                                )
                            }) :
                                <Typography sx={{color: '#646464'}}>No alias yet.</Typography>
                            }
                        </Box>
                        <Typography sx={{marginBottom: 2, fontWeight: 700}}>
                            Add new alias
                        </Typography>
                        <Box sx={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center'}}>

                            <TextField
                                id="new-alias-select"
                                value={newAlias}
                                label="New alias"
                                onChange={handleSelectAliasChange}
                                select
                                fullWidth
                            >
                                <MenuItem value={'champion'}>Champion</MenuItem>
                                <MenuItem value={'production'}>Production</MenuItem>
                            </TextField>


                            <IconButton sx={{
                                padding: '5px 10px',
                                marginLeft: '10px',
                                minWidth: 'fit-content',
                                lineHeight: 'normal',
                                borderRadius: '8px',
                            }}
                                onClick={handleAddNewAlias}>
                                <AddIcon />
                            </IconButton>
                        </Box>
                    </Box>
                    <DialogActions sx={{marginTop: 2}}>
                        <Button sx={{backgroundColor: 'rgba(0, 167, 111, 0.16)', padding: 1, paddingLeft: 2, paddingRight: 2, color: 'rgb(0, 120, 103)', fontWeight: 700}}
                                onClick={handleNewAliasesConfirmChange} autoFocus>
                            Save
                        </Button>
                        <Button sx={{backgroundColor: 'rgba(255, 86, 48, 0.16)', padding: 1, paddingLeft: 2, paddingRight: 2, color: 'rgb(183, 29, 24)', fontWeight: 700}}
                                onClick={handleCancelNewAliases}>
                            Cancel
                        </Button>
                    </DialogActions>
                </Paper>
            </Modal>

            <DeleteModelModal
                deleteModalOpen={deleteModalOpen}
                handleCloseFromProps={handleClose}
                currentModel={currentModel}
                handleDeleteModelVersionFromProps={handleDeleteModelVersion}
                handleCancelDeleteModelVersionFromProps={handleCancelDeleteModelVersion}
            />
        </Grid>
    )
}

export default AdminModelsDashboard