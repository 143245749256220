import {FC} from "react";
import Header from "../components/common/Header";
import PredictionsMap from "../components/map/PredictionsMap";


type MapPageProps = {}

const MapPage:FC<MapPageProps> = () => {

    return (
        <>
            <Header />
            <PredictionsMap />
        </>
    )
}

export default MapPage