import React, {createContext, useEffect, useState} from 'react';
import {airPollutionApi} from "../utils/axios";
import {PredictionsResponseType} from "../types/predictions";
import {StationType} from "../types/stations";
import {originalStations} from "../constants/stations";

export type StationContextType = {
    stations: Array<StationType>,
    currentData: PredictionsResponseType | null
}

export const StationContext = createContext<StationContextType>({
    stations: [],
    currentData: null
});

export const getNextHourDelay = () => {
    const now = new Date();
    const nextHour = new Date(now.getFullYear(), now.getMonth(), now.getDate(), now.getHours() + 1, 5, 0, 0);
    return nextHour.getTime() - now.getTime();
};

const StationsProvider = ({ children }: any) => {
    const stations: Array<StationType> = originalStations
    const [currentData, setCurrentData] = useState<PredictionsResponseType | null>(null)

    const fetchNewPredictions = async () => {
        const result = await airPollutionApi.get('/predict/predict-all')

        const predictionType: PredictionsResponseType = {
            datetime: new Date().toISOString(),
            predictions: result.data.predictions
        }

        setCurrentData(predictionType)
    }

    const setHourlyInterval = () => {
        fetchNewPredictions()
        setInterval(fetchNewPredictions, 60 * 60 * 1000)
    };

    useEffect(() => {
        fetchNewPredictions()

        const initialDelay = getNextHourDelay()
        const timeoutId = setTimeout(setHourlyInterval, initialDelay)

        return () => clearTimeout(timeoutId)
    }, [])

  return <StationContext.Provider value={{stations, currentData}}>{children}</StationContext.Provider>
};

export default StationsProvider;