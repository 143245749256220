import React, {FC} from "react";
import {Grid} from "@mui/material";
import {useResponsive} from "../../../hooks/responsive";
import IFrameContainer from "../../common/IFrameContainer";


type AdminTestingDashboardProps = {}

const AdminTestingDashboard:FC<AdminTestingDashboardProps> = () => {
    const isMd = useResponsive('up', 'md')

    return (
        <Grid sx={{backgroundColor: '#F7F7F7', height: '100%', paddingBottom: isMd ? 0 : 12}}>
            <IFrameContainer siteURL={process.env.REACT_APP_TESTS_SITE_URI || ''} title={'Testing report'} />
        </Grid>
    )
}

export default AdminTestingDashboard