import {Box, CircularProgress} from "@mui/material";
import React from "react";


const BaseCircularProgress = () => {

    return (
        <Box sx={{ display: 'flex' }}>
            <CircularProgress sx={{
                color: '#0F123F',
                '& .MuiLinearProgress-bar': {
                    color: '#0F123F'
                }}}
            />
        </Box>
    )
}

export default BaseCircularProgress