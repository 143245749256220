import React, {FC} from "react";
import Header from "../components/common/Header";
import {Button, Grid} from "@mui/material";
import Footer from "../components/common/Footer";
import {useNavigate} from "react-router-dom";
import {useResponsive} from "../hooks/responsive";
import AqiEmojisAll from '../assets/aqi-emojis-all.png'
import AQIndexInformationContainer from "../components/landing-page/AQIndexInformationContainer";
import '../styles/clouds.css'
import '../styles/landing-page.css'
import Divider from "@mui/material/Divider";
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import TitleWithDescription from "../components/landing-page/TitleWithDescription";

type LandingPageProps = {

}


const LandingPage:FC<LandingPageProps> = () => {
    const navigation = useNavigate()
    const isResponsive = useResponsive('down', 'md')

    return (
        <>
            <Header />

            <Grid container sx={{paddingLeft: '5%', paddingRight: '5%', backgroundColor: 'rgba(0,180,255,0.3)'}}>
                <Grid container
                      sx={{marginTop: isResponsive ? '50px' : '150px', marginBottom: isResponsive ? '100px' : '150px'}}
                >
                    <Grid item xs={12} md={6}
                          sx={{
                              display: 'flex',
                              flexDirection: 'row',
                              justifyContent: 'center',
                              alignItems: 'center',
                              marginBottom: isResponsive ? '50px' : 0,
                              zIndex: 5
                          }}
                    >
                        <img
                            src={AqiEmojisAll}
                            alt={'Emojis for air quality index'}
                            style={{width: '80%', height: 'fit-content'}}/>
                    </Grid>

                    <Grid item xs={12} md={6} sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        zIndex: 10
                    }}
                    >
                        <Grid item xs={10} md={8} sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'flex-start'
                        }}>

                            <TitleWithDescription
                                title={'Air Quality Prediction in Slovenia'}
                                description={'This project uses machine learning to predict key air quality indicators, ' +
                                    'in Slovenia. By forecasting particulate matter concentrations, it showcases the ' +
                                    'potential of data-driven approaches to tackle environmental challenges and protect ' +
                                    'public health.'}
                            />

                            <Button
                                className={'predictions-button'}
                                sx={{
                                    alignSelf: 'bottom',
                                }}
                                onClick={() => {
                                    navigation('/map')
                                }}
                            >
                                View predictions
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>

                <div className={'cloud-background'}>
                    <div id="background-wrap">
                        <div className="x0">
                            <div className="cloud"></div>
                        </div>

                        <div className="x1">
                            <div className="cloud"></div>
                        </div>

                        <div className="x2">
                            <div className="cloud"></div>
                        </div>

                        <div className="x3">
                            <div className="cloud"></div>
                        </div>

                        <div className="x4">
                            <div className="cloud"></div>
                        </div>

                        <div className="x5">
                            <div className="cloud"></div>
                        </div>

                        <div className="x6">
                            <div className="cloud"></div>
                        </div>

                    </div>
                </div>
            </Grid>

            <Grid container sx={{paddingLeft: '5%', paddingRight: '5%'}} >

                <Grid container sx={{
                    marginTop: isResponsive ? '100px' : '75px',
                    marginBottom: isResponsive ? '100px' : '75px',
                    zIndex: 5
                }}>

                    <Grid item xs={12} md={5}
                          sx={{
                              display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center',
                              marginBottom: isResponsive ? '50px' : 0
                          }}>
                        <Grid item xs={10} md={8}
                              sx={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                  justifyContent: 'center',
                                  alignItems: 'center'
                              }}>

                            <TitleWithDescription
                                title={'Understanding Air Quality and the AQI'}
                                description={'Air quality affects health, ecosystems, and the climate. The Air Quality ' +
                                    'Index (AQI) measures pollutants such as PM2.5, PM10, ozone, sulfur dioxide, ' +
                                    'nitrogen dioxide, and carbon monoxide, with higher values indicating worse ' +
                                    'conditions. Monitoring the AQI helps vulnerable groups make informed health ' +
                                    'decisions.'}
                            />
                        </Grid>
                    </Grid>

                    <AQIndexInformationContainer/>
                </Grid>
            </Grid>

            <Grid sx={{paddingLeft: '5%', paddingRight: '5%', display: 'flex', width: '100%', maxWidth: '100%'}}>
                <Grid container sx={{zIndex: 5}}>
                    <Divider sx={{border: '1px solid #e2e2e2', width: '100%'}}/>
                </Grid>
            </Grid>

            <Grid container sx={{paddingLeft: '5%', paddingRight: '5%', }}>

                <Grid container sx={{
                    marginTop: isResponsive ? '100px' : '75px',
                    marginBottom: isResponsive ? '100px' : '75px',
                    zIndex: 5,
                    backgroundColor: 'rgba(244,137,30,0.23)',
                    paddingTop: isResponsive ? '50px' : '100px',
                    paddingBottom: isResponsive ? '50px' : '100px',
                    borderRadius: '12px'
                }}>
                    <Grid item xs={12} md={5} sx={{display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}}>
                        <Grid
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'center',
                                alignItems: 'center',
                                padding: isResponsive ? '16px 8px' : '8px 16px',
                                borderRadius: '12px',
                            }}
                        >
                            <WarningRoundedIcon sx={{fontSize: isResponsive ? '8rem' : '16rem', color: '#f4891e'}} />
                        </Grid>
                    </Grid>

                    <Grid item xs={12} md={7}
                          sx={{
                              display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center',
                              marginBottom: isResponsive ? '50px' : 0
                          }}>
                        <Grid item xs={10} md={8}
                              sx={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                  justifyContent: 'center',
                                  alignItems: 'flex-start'
                              }}>

                            <TitleWithDescription
                                title={'Disclaimer on Predictions'}
                                description={'The information provided on this website consists solely of predictions ' +
                                    'and should not be taken as absolute truth. These forecasts are based on machine ' +
                                    'learning models and are subject to inaccuracies. Always consult official sources ' +
                                    'for critical decisions regarding air quality and health.'}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            <Footer/>
        </>

    )
}

export default LandingPage