import React, {FC, useContext, useEffect, useState} from "react";
import {useResponsive} from "../../../hooks/responsive";
import {
    Button,
    DialogActions,
    Grid,
    MenuItem,
    Modal,
    Paper,
    Select,
    SelectChangeEvent,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from "@mui/material";
import FunctionsIcon from '@mui/icons-material/Functions';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import RestoreIcon from '@mui/icons-material/Restore';
import '../../../styles/admin-dashboard.css'
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import {User} from "../../../types/users";
import {UserType} from "../../../constants/users";
import {airPollutionApi} from "../../../utils/axios";
import {AuthContext} from "../../../context/AuthContext";


type UserManagementDashboardProps = {}

const UserManagementDashboard:FC<UserManagementDashboardProps> = () => {
    const isMd = useResponsive('up', 'md')
    const isLg = useResponsive('up', 'lg')
    const {userRole} = useContext(AuthContext)
    const [users, setUsers] = useState<Array<User> | null>(null)
    const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false)
    const [deletedUserEmail, setDeletedUserEmail] = useState<string>('')

    useEffect(() => {
        getUsers()
    }, []);

    const getUsers = async () => {
        try {
            const usersResponse = await airPollutionApi.get('/users/all')

            setUsers(usersResponse.data.users)
        } catch (err: any) {
            console.log('Fetching users error', err)
            setUsers([])
        }
    }

    const handleUserRoleChange = async (event: SelectChangeEvent<unknown>, email: string) => {
        try {
            const changeRoleResponse = await airPollutionApi.post('/users/change-role',
                {email, role: event.target.value})

            await getUsers()
        } catch (err: any) {
            console.log('Change role error', err)
        }

    }

    const handleDeleteUser = async () => {
        if (deletedUserEmail !== '') {
            try {
                const deletedResponse = await airPollutionApi.post('/users/delete-user', {email: deletedUserEmail})

                getUsers()

                setDeleteModalOpen(false)
                setDeletedUserEmail('')
            } catch (error: any) {
                console.log('Deleting user error:', error)
            }
        }
    }

    return (
        <Grid
            container
            className={'user-management-dashboard-container'}
            sx={{
                padding: isLg ? 6 : 2,
                rowGap: 7
            }}
        >
            <Grid sx={{display: 'flex', flexDirection: isMd ? 'row' : 'column', width: '100%', gap: 4}}>
                <Grid item xs={12} md={4}
                    sx={{
                        backgroundColor: '#fff',
                        borderRadius: '20px',
                        display: 'flex',
                        justifyContent: 'center',
                        flexDirection: 'column',
                        alignItems: 'center',
                        border: '1px solid #E6EDFF',
                        padding: '12px 24px'
                    }}
                >
                    <Grid sx={{display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}}>
                        <FunctionsIcon sx={{marginRight: '6px', color: '#545454'}} />
                        <Typography sx={{fontWeight: 700, fontSize: '1rem', color: '#545454', alignSelf: 'center'}}>
                            Total users
                        </Typography>
                    </Grid>

                    <Typography sx={{fontSize: '2.5rem', fontWeight: 800}}>
                        {users ? users.length : '--'}
                    </Typography>

                </Grid>

                <Grid item xs={12} md={4}
                    sx={{
                        backgroundColor: '#fff',
                        borderRadius: '20px',
                        display: 'flex',
                        justifyContent: 'center',
                        flexDirection: 'column',
                        alignItems: 'center',
                        border: '1px solid #E6EDFF',
                        padding: '12px 24px'
                    }}
                >
                    <Grid sx={{display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}}>
                        <SupervisorAccountIcon sx={{marginRight: '6px', color: '#545454'}} />
                        <Typography sx={{fontWeight: 700, fontSize: '1rem', color: '#545454', alignSelf: 'center'}}>
                            Admin users
                        </Typography>
                    </Grid>

                    <Typography sx={{fontSize: '2.5rem', fontWeight: 800}}>
                        {users ? users.filter((user) => user.role === UserType.ADMIN).length : '--'}
                    </Typography>

                </Grid>

                <Grid item xs={12} md={4}
                    sx={{
                        backgroundColor: '#fff',
                        borderRadius: '20px',
                        display: 'flex',
                        justifyContent: 'center',
                        flexDirection: 'column',
                        alignItems: 'center',
                        border: '1px solid #E6EDFF',
                        padding: '12px 24px'
                    }}
                >
                    <Grid sx={{display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}}>
                        <RestoreIcon sx={{marginRight: '6px', color: '#545454'}} />
                        <Typography sx={{fontWeight: 700, fontSize: '1rem', color: '#545454', alignSelf: 'center'}}>
                            Recent users
                        </Typography>
                    </Grid>

                    <Typography sx={{fontSize: '2.5rem', fontWeight: 800}}>
                        {users ? users.filter((item) => {return (new Date(item.created_at).getTime() >= new Date().setDate(new Date().getDate() - 14))}).length : '--'}
                    </Typography>

                </Grid>
            </Grid>

            <Grid sx={{width: '100%'}}>
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell className={'admin-users-table-header-cell'}>Email</TableCell>
                                <TableCell className={'admin-users-table-header-cell'}>Registered at</TableCell>
                                <TableCell className={'admin-users-table-header-cell'}>Role</TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {users && users.map((row: User) => {
                                return (
                                    <TableRow key={row.id}>
                                        <TableCell>
                                            {row.email}
                                        </TableCell>
                                        <TableCell>
                                            {new Date(row.created_at).toDateString()}
                                        </TableCell>
                                        <TableCell>
                                            <Select
                                                id="user-role-select"
                                                value={row.role as UserType}
                                                onChange={(event) => handleUserRoleChange(event, row.email)}
                                                disabled={row.email === 'zala.lahovnik1@um.si' || userRole !== UserType.ADMIN}
                                            >
                                                <MenuItem value={UserType.USER}>{UserType.USER}</MenuItem>
                                                <MenuItem value={UserType.ADMIN}>{UserType.ADMIN}</MenuItem>
                                            </Select>
                                        </TableCell>
                                        <TableCell align={'right'}>
                                            <IconButton
                                                disabled={row.email === 'zala.lahovnik1@um.si'}
                                                className={'admin-models-management-button'}
                                                onClick={() => {setDeletedUserEmail(row.email); setDeleteModalOpen(true)}}
                                                sx={{
                                                    color: '#e52626',
                                                    padding: '5px',
                                                    width: 'fit-content',
                                                    marginLeft: 1
                                                }}
                                            >
                                                <DeleteIcon />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                )
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>

            <Modal
                open={deleteModalOpen}
                onClose={() => {setDeleteModalOpen(false); setDeletedUserEmail('')}}
                aria-labelledby="delete-model-modal-title"
                aria-describedby="delete-model-modal-description"
                sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '5px 10px'}}
            >
                <Paper sx={{padding: 4, paddingLeft: 4, paddingRight: 4, minWidth: '20%'}}>
                    <Typography id="delete-model-modal-title" variant="h6" component="h2" sx={{marginBottom: 2}}>
                        Delete user {deletedUserEmail}?
                    </Typography>
                    <Typography id="delete-model-modal-description" sx={{ mb: 4 }}>
                        This action is irreversible!
                    </Typography>
                    <DialogActions>
                        <Button sx={{backgroundColor: 'rgba(255, 86, 48, 0.16)', padding: 1, paddingLeft: 2, paddingRight: 2, color: 'rgb(183, 29, 24)', fontWeight: 700}}
                                onClick={handleDeleteUser}>
                            Delete
                        </Button>
                        <Button sx={{backgroundColor: '#cecece', padding: 1, paddingLeft: 2, paddingRight: 2, color: '#000000', fontWeight: 700}}
                                onClick={() => {setDeleteModalOpen(false); setDeletedUserEmail('')}} autoFocus>
                            Cancel
                        </Button>
                    </DialogActions>
                </Paper>
            </Modal>

        </Grid>
    )
}

export default UserManagementDashboard