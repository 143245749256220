export type MonitorDataType = {
    datetime: string,
    pm10_pro: {
        mae: number,
        mse: number,
        evs: number
    },
    pm25_pro: {
        mae: number,
        mse: number,
        evs: number
    },
    pm10_champ: {
        mae: number,
        mse: number,
        evs: number
    } | null,
    pm25_champ: {
        mae: number,
        mse: number,
        evs: number
    } | null,
    station: string
}

export type MonitorDataDisplayType = {
    dates: Array<Date>,
    pm10_pro: {
        mae: Array<number| null>,
        mse: Array<number | null>,
        evs: Array<number | null>
    },
    pm25_pro: {
        mae: Array<number| null>,
        mse: Array<number | null>,
        evs: Array<number | null>
    },
    pm10_champ: {
        mae: Array<number| null>,
        mse: Array<number | null>,
        evs: Array<number | null>
    },
    pm25_champ: {
        mae: Array<number| null>,
        mse: Array<number | null>,
        evs: Array<number | null>
    }
}


export type PredictedDataResponseObjectType = {
    pred_pm10_pro: number | null,
    pred_pm25_pro: number | null,
    pred_pm10_champ: number | null,
    pred_pm25_champ: number | null,
    PM10: number | null,
    'PM2.5': number | null,
    date: string
}

export type PredictedDataDisplayType = {
    PM10: Array<number | null>,
    "PM2.5": Array<number | null>,
    date: Array<Date>,
    pred_pm10_champ: Array<number | null>,
    pred_pm10_pro: Array<number | null>,
    pred_pm25_champ: Array<number | null>,
    pred_pm25_pro: Array<number | null>,
}

export enum MonitorViewType {
    METRICS = "METRICS",
    VALUES = "VALUES"
}