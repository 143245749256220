import {Grid, Typography} from "@mui/material";
import React, {FC} from "react";
import '../../styles/map-page.css'

type PredictedValuesCardProps = {
    value: number | string | null,
    title: string,
    unit: string
}


const PredictedValuesCard:FC<PredictedValuesCardProps> = ({value, title, unit}) => {

    return (
        <Grid item xs={4} sm={2} md={4}
              className={'predicted-values-card-container'}
              sx={{ alignItems: 'space-between' }}
        >
            <Typography className={'predicted-values-card-type'}>
                {title}
            </Typography>

            <Typography align={'center'} className={'predicted-values-card-value'} >
                {value || '--'}
            </Typography>

            <Typography align={'center'} className={'predicted-values-card-unit'} >
                {unit}
            </Typography>
        </Grid>
    )
}

export default PredictedValuesCard