import React, {FC} from "react";
import {Button} from "@mui/material";
import {useLocation, useNavigate} from "react-router-dom";


type SideMenuButtonProps = {
    navigationLink: string,
    setOpenFromProps: (open: boolean) => void,
    icon: any,
    text: string
}

const SideMenuButton:FC<SideMenuButtonProps> = ({navigationLink, setOpenFromProps, icon, text}) => {
    const navigation = useNavigate()
    const routePath = useLocation()

    return (
        <Button className={routePath.pathname === navigationLink ? 'drawer-button-active' : 'drawer-button'}
            sx={{display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'center'
            }}
            onClick={() => {navigation(navigationLink); setOpenFromProps(false)}}
        >
            {icon}
            <div>{text}</div>
        </Button>
    )
}

export default SideMenuButton