import {Grid} from "@mui/material";
import StationSideMenu from "../components/side-menus/StationSideMenu";
import React from "react";
import {useResponsive} from "../hooks/responsive";


const BasePageTemplate = ({children}: any) => {
    const responsive = useResponsive('up', 'md')

    return (
        <Grid container>
            <Grid item xs={12} md={6} lg={4}>
                <StationSideMenu />
            </Grid>
            <Grid item xs={12} md={6} lg={8} sx={{height: responsive ? '100%': '70%'}}>
                {children}
            </Grid>
        </Grid>
    )
}

export default BasePageTemplate