import React, {FC} from "react";
import {Grid} from "@mui/material";
import {useResponsive} from "../../../hooks/responsive";
import IFrameContainer from "../../common/IFrameContainer";


type AdminValidationDashboardProps = {}

const AdminValidationDashboard:FC<AdminValidationDashboardProps> = () => {
        const isMd = useResponsive('up', 'md')

    return (
        <Grid sx={{backgroundColor: '#F7F7F7', height: '100%', paddingBottom: isMd ? 0 : 12}}>
            <IFrameContainer siteURL={process.env.REACT_APP_VALIDATION_SITE_URI || ''} title={'Validation report'} />
        </Grid>
    )
}

export default AdminValidationDashboard