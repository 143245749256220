import React, {FC} from "react";
import SatelliteAltIcon from "@mui/icons-material/SatelliteAlt";
import Divider from "@mui/material/Divider";
import {
    aqiMapping,
    aqiMappingBackgroundColor,
    calculateAQIFromPM10AndPM25
} from "../../utils/aqiMapping";
import {Paper} from "@mui/material";


type CurrentPredictionCardProps = {
    stationName: string,
    pred_pm10: number,
    pred_pm25: number
}

const CurrentPredictionCard:FC<CurrentPredictionCardProps> = ({stationName, pred_pm10, pred_pm25}) => {

    return (
        <Paper className={'station-predictions-container'}>
            <div className={'station-predictions-station-name'}>
                <SatelliteAltIcon sx={{marginRight: 2}}/>
                {stationName}
            </div>
            <Divider component="div" sx={{marginTop: 1, marginBottom: 1}}/>
            <div className={'station-predictions-pm-values'}>
                <div className={'station-predictions-pm-values-title'}>
                    PM10 values:
                </div>
                <div>
                    {pred_pm10}
                </div>
            </div>
            <div className={'station-predictions-pm-values'}>
                <div className={'station-predictions-pm-values-title'}>
                    PM2.5 values:
                </div>
                <div>
                    {pred_pm25}
                </div>
            </div>
            <div className={'station-predictions-status-container'}>
                <div className={'station-predictions-status-title'}>
                    Status
                </div>
                <div className={'station-predictions-status-value'} style={{
                    backgroundColor: aqiMappingBackgroundColor(calculateAQIFromPM10AndPM25(pred_pm10, pred_pm25)),
                    color: '#fff'
                }}>
                    {pred_pm10 ? aqiMapping(calculateAQIFromPM10AndPM25(pred_pm10, pred_pm25)) : 'Unknown'}
                </div>
            </div>
        </Paper>
    )
}

export default CurrentPredictionCard