import React, {FC} from "react";
import AdminPageTemplate from "../templates/AdminPageTemplate";
import UserManagementDashboard from "../components/admin/users/UserManagementDashboard";


type UserManagementPageProps = {}


const UserManagementPage:FC<UserManagementPageProps> = () => {

    return (
        <AdminPageTemplate children={<UserManagementDashboard />} />
    )
}

export default UserManagementPage