import React, {FC, useContext, useEffect, useState} from 'react'
import {MapContainer, Marker, TileLayer, Tooltip} from "react-leaflet";
import 'leaflet/dist/leaflet.css'
import '../../styles/map-page.css'
//@ts-ignore
import L from 'leaflet'
import {getNextHourDelay, StationContext} from "../../context/StationsContext";
import {
    aqiMappingColor,
    calculateAQIFromPM10AndPM25
} from "../../utils/aqiMapping";
import {airPollutionApi} from "../../utils/axios";
import {Grid} from "@mui/material";
import {
    ForecastGraphDataType,
    PredictionType,
    SingleStationPredictionResponseType, SingleStationPredictionType,
} from "../../types/predictions";
import {StationType} from "../../types/stations";
import {useResponsive} from "../../hooks/responsive";
import AQIGoodIcon from '../../assets/aqi-emoji-good.gif'
import AQIModerateIcon from '../../assets/aqi-emoji-moderate.gif'
import AQIUnhealthySGIcon from '../../assets/aqi-emoji-unhealthy-sg.gif'
import AQIUnhealthyIcon from '../../assets/aqi-emoji-unhealthy.gif'
import AQIVeryUnhealthyIcon from '../../assets/aqi-emoji-very-unhealthy.gif'
import AQIHazardousIcon from '../../assets/aqi-emoji-hazardous.gif'
import AQIUnknownIcon from '../../assets/aqi-emoji-unknown.gif'
import StationCard from "./StationCard";


type StationMapProps = {
}


const StationMap: FC<StationMapProps> = ({}) => {
    const attribution = '&copy; <a href="https://www.stadiamaps.com/" target="_blank">Stadia Maps</a> &copy; <a href="https://openmaptiles.org/" target="_blank">OpenMapTiles</a> &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
    const url = `https://tiles.stadiamaps.com/tiles/alidade_smooth/{z}/{x}/{y}{r}.png?api_key=${process.env.REACT_APP_MAP_API_KEY}`
    const [station, setStation] = useState<StationType | null>(null)
    const { stations, currentData} = useContext(StationContext)
    const [predictions, setPredictions] = useState<SingleStationPredictionResponseType | null>(null)
    const [graphData, setGraphData] = useState<ForecastGraphDataType | null>(null)
    const isResponsive = useResponsive('down', 'md')
    const [AQIEmojiIcon, setAQIEmojiIcon] = useState<any>(AQIUnknownIcon)

    const onClickOnMarker = (event: any) => {
        const { lat, lng } = event.latlng

        const found = stations.find((stand) => (stand.latitude === lat && stand.longitude === lng))

        if(found)
            setStation(found)
    }

    const getMarkerIcon = (station: string) => {
        let currentIcon = new L.Icon({
          iconUrl: require('../../assets/marker-icon-orange.png'),
          iconSize: [40, 40], // Size of the icon
          iconAnchor: [16, 32], // Point of the icon which will correspond to marker's location
          popupAnchor: [0, -32] // Point from which the popup should open relative to the iconAnchor
        });

        if (currentData) {
            const currentStationData = currentData.predictions.find((currentDataItem: PredictionType) => currentDataItem.station === station)
            if (currentStationData) {
                const currentAqi = aqiMappingColor(calculateAQIFromPM10AndPM25(currentStationData.pred_pm10, currentStationData.pred_pm25))

                currentIcon = new L.Icon({
                  iconUrl: require(`../../assets/marker-icon-${currentAqi}.png`),
                  iconSize: [40, 40], // Size of the icon
                  iconAnchor: [16, 32], // Point of the icon which will correspond to marker's location
                  popupAnchor: [0, -32] // Point from which the popup should open relative to the iconAnchor
                });
            }
        }

        return currentIcon
    }

    const fetchStationPredictions = async () => {
        if(station) {
            try {
                const response = await airPollutionApi.post('/predict/predict', {station: station.serial})

                setPredictions(response.data)

                const currentAQI = calculateAQIFromPM10AndPM25(response.data.predictions[0].PM10, response.data.predictions[0]["PM2.5"])

                const currentIcon = getEmojiIconForAQI(currentAQI)

                setAQIEmojiIcon(currentIcon)

                const tempGraphData = convertToGraphData(response.data)

                setGraphData(tempGraphData)
            } catch (error) {
                console.log('predictions error', error)
            }

        }
    }

    const getEmojiIconForAQI = (aqi_value: number) => {
        if (aqi_value >= 0 && aqi_value <= 50)
            return AQIGoodIcon
        else if (aqi_value > 50 && aqi_value <= 100)
            return AQIModerateIcon
        else if (aqi_value > 100 && aqi_value <= 150)
            return AQIUnhealthySGIcon
        else if (aqi_value > 150 && aqi_value <= 200)
            return AQIUnhealthyIcon
        else if (aqi_value > 200 && aqi_value <= 300)
            return AQIVeryUnhealthyIcon
        else if (aqi_value > 300 && aqi_value <= 500)
            return AQIHazardousIcon
        else
            return AQIUnknownIcon
    }


    const convertToGraphData = (fetchedData: SingleStationPredictionResponseType) => {
        let tempPredictionArray: ForecastGraphDataType = {
            PM10: [],
            "PM2.5": [],
            dates: [],
            aqi: []
        }

        fetchedData.predictions.sort((date1, date2) =>  { // @ts-ignore
            return (new Date(date1.date) - new Date(date2.date))}).forEach((fetchedItem: SingleStationPredictionType) => {
            tempPredictionArray.dates.push(new Date(fetchedItem.date))
            tempPredictionArray.PM10.push(fetchedItem.PM10)
            tempPredictionArray["PM2.5"].push(fetchedItem["PM2.5"])

            tempPredictionArray.aqi.push(calculateAQIFromPM10AndPM25(fetchedItem.PM10, fetchedItem["PM2.5"]))
        })

        return tempPredictionArray
    }

    const changeAQIEmojiIcon = () => {
        setAQIEmojiIcon(AQIUnknownIcon)
    }


    useEffect(() => {
        if(station) {
            setAQIEmojiIcon(AQIUnknownIcon)
            setPredictions(null)
            fetchStationPredictions()
        }
    }, [station])

    const setHourlyInterval = () => {
        fetchStationPredictions()
        setInterval(fetchStationPredictions, 60 * 60 * 1000)
    };

    useEffect(() => {
        fetchStationPredictions()

        const initialDelay = getNextHourDelay()
        const timeoutId = setTimeout(setHourlyInterval, initialDelay)

        return () => clearTimeout(timeoutId)
    }, [])


    return (
        <div>
            <Grid container
                sx={{
                    height: '100%',
                    paddingLeft: '5%',
                    paddingRight: '5%',
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    flexWrap: 'wrap',
                    overflowY: 'hidden'
                }}
            >
                {station &&
                    <Grid className={'station-card-container'} item xs={12} md={6} lg={4}
                       sx={{
                           backgroundColor: '#fff',
                           width: '100%',
                           borderRadius: '15px',
                           padding: '30px',
                           boxShadow: '4px 4px 4px rgba(0,0,0,0.25)',
                           marginTop: isResponsive ? '45px' : '64px',
                           border: '1px solid #E2E2E2',
                           overflowY: isResponsive ? 'scroll': undefined,
                           display: isResponsive ? 'flex': 'absolute',
                           flexDirection: 'column',
                           maxHeight: '90vh'
                       }}
                    >
                        <StationCard
                            station={station}
                            predictions={predictions}
                            AQIEmojiIcon={AQIEmojiIcon}
                            graphData={graphData}
                            setStationFromProps={setStation}
                            setGraphDataFromProps={setGraphData}
                            setAQIEmojiIconFromProps={changeAQIEmojiIcon}
                        />
                    </Grid>
                }
            </Grid>


            <div style={{height: '90.5vh'}}>
                {/*@ts-ignore*/}
                <MapContainer key={'map-container'} center={[46.155, 15.05333]} zoom={9} minZoom={7} maxZoom={13}
                              scrollWheelZoom={true} zoomControl={false}>
                    {/*@ts-ignore*/}
                    <TileLayer key={'map-tile-layer'} attribution={attribution} url={url}/>

                    {currentData && stations?.map((stationContextItem: StationType, index: number) => {
                        return (
                            <div key={stationContextItem.serial + stationContextItem.label + index + '-0'}>
                                {/*@ts-ignore*/}
                                <Marker key={stationContextItem.serial + stationContextItem.label + index + '-1'} position={[stationContextItem.latitude,stationContextItem.longitude]} icon={getMarkerIcon(stationContextItem.serial)} eventHandlers={{click: (e) => onClickOnMarker(e)}}>
                                    <Tooltip key={stationContextItem.serial + stationContextItem.label + index + '-2'}>
                                        {stationContextItem.label}
                                    </Tooltip>
                                </Marker>
                            </div>
                        )
                    })}

                </MapContainer>
            </div>
        </div>
    )
}

export default StationMap