import React, {FC} from "react";
import AdminTestingDashboard from "../components/admin/testing/AdminTestingDashboard";
import AdminPageTemplate from "../templates/AdminPageTemplate";


const AdminTestingPage:FC = () => {
    return (
        <AdminPageTemplate children={<AdminTestingDashboard />} />
    )
}

export default AdminTestingPage