import {airPollutionApi} from "../utils/axios";
import axios from "axios";
import {createContext, Dispatch, SetStateAction, useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {UserType} from "../constants/users";


export type AuthContextType = {
    isLoggedIn: boolean | null,
    userRole: UserType | null,
    loginUser: (email: string, password: string) => void,
    loginError: string,
    setLoginError: Dispatch<SetStateAction<string>>,
    logoutUser: () => void,
    checkToken: () => void
};

export const AuthContext = createContext<AuthContextType>({
    isLoggedIn: null,
    userRole: null,
    loginUser: (email: string, password: string) => {},
    loginError: '',
    setLoginError: (value) => {},
    logoutUser: () => {},
    checkToken: () => {}
});


const AuthProvider = ({children}: any) => {
    const navigate = useNavigate()
    const [isLoggedIn, setIsLoggedIn] = useState<boolean | null>(null)
    const [userRole, setUserRole] = useState<UserType | null>(null)
    const [loginError, setLoginError] = useState<string>('')

    useEffect(() => {
        airPollutionApi.interceptors.request.use((request) => {
            if(request.headers)
                request.headers['Authorization'] = 'Bearer ' + localStorage.getItem('jwtToken') || ''

            return request
            },
        (error) => { return Promise.reject(error) }
        )

        airPollutionApi.interceptors.response.use((response) => response, (error) => {
            console.log('error', error)
            if(axios.isAxiosError(error) && (error.response?.status === 401 || error.response?.status === 422)) {
                logoutUser()
                setLoginError('Invalid token. Please login again.')
            }
            return Promise.reject(error)
        })
    }, [])

    const loginUser = async (email: string, password: string) => {
        try {
            const loginResponse = await airPollutionApi.post('/auth/login', {email, password})

            if(loginResponse.status === 200 && loginResponse.data.token !== null) {
                localStorage.setItem('jwtToken', loginResponse.data.token)
                setUserRole(loginResponse.data.role as UserType)
                setIsLoggedIn(true)
                navigate('/dashboard')
            } else {
                setLoginError(loginResponse.data.msg || '')
            }

        } catch (err: any) {
            console.log('err', err)
            setLoginError(err.response.data.msg || '')
        }
    }

    const logoutUser = () => {
        setUserRole(null)
        setIsLoggedIn(false)
        localStorage.removeItem('jwtToken')
    }

    useEffect(() => {
        const currentToken = localStorage.getItem('jwtToken') || ''

        if(currentToken) {
            checkToken()
        } else {
            setIsLoggedIn(false)
        }

    }, []);

    const checkToken = async () => {
        try {
            const checkedToken = await airPollutionApi.get('/auth/check-token')

            if(checkedToken.status === 200) {
                setIsLoggedIn(true)
                setUserRole(checkedToken.data.role as UserType)
            }
        } catch (err: any) {
            if(err?.response?.status === 401) {
                setIsLoggedIn(false)
                setUserRole(null)
            } else {
                console.log('Check token error:', err)
            }
        }
    }

    return (
        <AuthContext.Provider value={{isLoggedIn, userRole, loginUser, loginError, setLoginError, logoutUser, checkToken}}>
            {children}
        </AuthContext.Provider>
    )
}

export default AuthProvider;