import React, {FC} from "react";
import {Grid, Typography} from "@mui/material";
import CustomLineChart from "./CustomLineChart";
import {useResponsive} from "../../hooks/responsive";
import {ForecastGraphDataType} from "../../types/predictions";


type ForecastPredictionsCardProps = {
    graphData: ForecastGraphDataType | null
}


const ForecastPredictionsCard:FC<ForecastPredictionsCardProps> = ({graphData}) => {
    const isResponsive = useResponsive('down', 'md')


    return (
        <>
            <Grid item xs={12}>
                <Typography className={'forecast-predictions-card-title'}>
                    Forecast
                </Typography>
            </Grid>

            <Grid item xs={12} md={6}>
                <Typography className={'forecast-predictions-card-subtitle'}>
                    PM10
                </Typography>

                {graphData ?
                    <CustomLineChart
                        key={graphData.dates[0] + 'PM10'}
                        data={graphData?.PM10}
                        dates={graphData.dates}
                        height={150}
                        thresholds={[0, 55, 155, 255, 355, 425, 604]}
                        colors={['#000', '#0aa24f', '#eacd2a', '#f4891e', '#e81c2b', '#6a3499', '#550000', '#000']}
                        axisLabel={'PM10'}
                    />
                :
                    <CustomLineChart
                        data={[0, 0, 0, 0, 0, 0]}
                        dates={[new Date(2024, 7, 25, 0),new Date(2024, 7, 25, 1), new Date(2024, 7, 25, 2), new Date(2024, 7, 25, 3), new Date(2024, 7, 25, 4), new Date(2024, 7, 25, 5)]}
                        height={150}
                        thresholds={[0, 55, 155, 255, 355, 425, 604]}
                        colors={['#000', '#0aa24f', '#eacd2a', '#f4891e', '#e81c2b', '#6a3499', '#550000', '#000']}
                        axisLabel={'PM10'}
                    />
                }
            </Grid>

            <Grid item xs={12} md={6} sx={{marginTop: isResponsive ? '10px' : 0}}>
                <Typography className={'forecast-predictions-card-subtitle'}>
                    PM2.5
                </Typography>

                {graphData ?
                    <CustomLineChart
                        key={graphData.dates[0] + 'PM2.5'}
                        data={graphData["PM2.5"]}
                        dates={graphData.dates}
                        height={150}
                        thresholds={[0, 12.0000001, 35.4, 55.5, 150.4, 250.4, 500.4]}
                        colors={['#000', '#0aa24f', '#eacd2a', '#f4891e', '#e81c2b', '#6a3499', '#550000', '#000']}
                        axisLabel={'PM2.5'}
                    />
                :
                    <CustomLineChart
                        data={[0, 0, 0, 0, 0, 0]}
                        dates={[new Date(2024, 7, 25, 0),new Date(2024, 7, 25, 1), new Date(2024, 7, 25, 2), new Date(2024, 7, 25, 3), new Date(2024, 7, 25, 4), new Date(2024, 7, 25, 5)]}
                        height={150}
                        thresholds={[0, 12.0000001, 35.4, 55.5, 150.4, 250.4, 500.4]}
                        colors={['#000', '#0aa24f', '#eacd2a', '#f4891e', '#e81c2b', '#6a3499', '#550000', '#000']}
                        axisLabel={'PM2.5'}
                    />
                }
            </Grid>

            <Grid item xs={12} md={12} sx={{marginTop: isResponsive ? '10px' : 0}}>
                <Typography className={'forecast-predictions-card-subtitle'}>
                    AQI
                </Typography>

                {graphData ?
                        <CustomLineChart
                            key={graphData.dates[0] + 'AQI'}
                            data={graphData.aqi}
                            dates={graphData.dates}
                            height={150}
                            thresholds={[0, 51, 101, 151, 201, 301, 501]}
                            colors={['#000', '#0aa24f', '#eacd2a', '#f4891e', '#e81c2b', '#6a3499', '#550000', '#000']}
                            axisLabel={'AQI'}
                        />
                    :
                        <CustomLineChart
                            data={[0, 0, 0, 0, 0, 0]}
                            dates={[new Date(2024, 7, 25, 0),new Date(2024, 7, 25, 1), new Date(2024, 7, 25, 2), new Date(2024, 7, 25, 3), new Date(2024, 7, 25, 4), new Date(2024, 7, 25, 5)]}
                            height={150}
                            thresholds={[0, 51, 101, 151, 201, 301, 501]}
                            colors={['#000', '#0aa24f', '#eacd2a', '#f4891e', '#e81c2b', '#6a3499', '#550000', '#000']}
                            axisLabel={'AQI'}
                        />
                    }
            </Grid>
        </>
    )
}

export default ForecastPredictionsCard