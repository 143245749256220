import React from 'react';
import './App.css';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import HomePage from "./pages/HomePage";
import AdminDashboardPage from "./pages/AdminDashboardPage";
import AdminModelsPage from "./pages/AdminModelsPage";
import AdminValidationPage from "./pages/AdminValidationPage";
import AdminTestingPage from "./pages/AdminTestingPage";
import StationsProvider from "./context/StationsContext";
import LoginPage from "./pages/LoginPage";
import MapPage from "./pages/MapPage";
import {createTheme, ThemeProvider} from '@mui/material/styles';
import {CssBaseline} from "@mui/material";
import LandingPage from "./pages/LandingPage";
import {AdminRoute, ProtectedRoute} from "./components/auth/ProtectedRoute";
import AuthProvider from "./context/AuthContext";
import UserManagementPage from "./pages/UserManagementPage";

function App() {

    const theme = createTheme({
        typography: {
            fontFamily: 'Inter, Arial, sans-serif',
        },
    });

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <BrowserRouter>
                <StationsProvider>
                    <AuthProvider>
                        <Routes>
                            <Route path={'/'} element={<LandingPage />} />
                            <Route path="/old-map" element={<HomePage />}/>
                            <Route path={'/map'} element={<MapPage />} />
                            <Route path="/login" element={<LoginPage />}/>
                            <Route path="dashboard" element={<ProtectedRoute><AdminDashboardPage /></ProtectedRoute>}/>
                            <Route path="models" element={<AdminRoute><AdminModelsPage /></AdminRoute>}/>
                            <Route path="validation" element={<AdminRoute><AdminValidationPage /></AdminRoute>}/>
                            <Route path="testing" element={<AdminRoute><AdminTestingPage /></AdminRoute>}/>
                            <Route path="users" element={<AdminRoute><UserManagementPage /></AdminRoute>}/>
                        </Routes>
                    </AuthProvider>
                </StationsProvider>
            </BrowserRouter>
        </ThemeProvider>
    );
}

export default App;
