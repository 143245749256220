import React, {FC, useContext, useState} from "react";
import {Button, Grid, Menu, MenuItem, Typography} from "@mui/material";
import '../../styles/admin-side-menu.css'
import DashboardIcon from '@mui/icons-material/Dashboard';
import ModelTrainingIcon from '@mui/icons-material/ModelTraining';
import HomeIcon from '@mui/icons-material/Home';
import AssessmentIcon from '@mui/icons-material/Assessment';
import {useLocation, useNavigate} from "react-router-dom";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ManageAccountsRoundedIcon from '@mui/icons-material/ManageAccountsRounded';
import MapIcon from '@mui/icons-material/Map';
import AdminSideButton from "./AdminSideButton";
import {Logout} from "@mui/icons-material";
import {AuthContext} from "../../context/AuthContext";
import {UserType} from "../../constants/users";

type AdminSideMenuProps = {
}

const AdminSideMenu:FC<AdminSideMenuProps> = () => {
    const navigation = useNavigate()
    const routePath = useLocation()
    const [reportAnchorEl, setReportAnchorEl] = useState<null | HTMLElement>(null)
    const [open, setOpen] = useState(false)
    const {logoutUser, userRole} = useContext(AuthContext)

    const handleReportsClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setOpen(true)
        setReportAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
        setOpen(false)
        setTimeout(() => {
            setReportAnchorEl(null);
        }, 300)
    };


    return (
        <Grid container sx={{
            padding: 1,
            paddingTop: 4,
            backgroundColor: '#0F123F',
            height: '100% !important',
            color: 'white',
            borderBottomRightRadius: '16px',
            borderTopRightRadius: '16px',
            display: 'flex',
            flexDirection: 'row',
            alignContent: 'flex-start'
        }}>
            <Grid item xs={12} sx={{height: 'fit-content', display: 'flex', justifyContent: 'center'}}>
                <Typography variant={'h2'} component={'h2'} className={'admin-main-title'}
                    onClick={() => {navigation('/')}}
                >
                    Airly
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Grid item sx={{
                    paddingTop: 4,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    height: '100%',
                    width: '100%',
                    color: 'white',
                }}>
                    <AdminSideButton navigationLink={'/'} icon={<HomeIcon sx={{marginRight: 2}} />} text={'Home'} />

                    <AdminSideButton
                        navigationLink={'/map'}
                        icon={<MapIcon sx={{marginRight: 2}} />}
                        text={'Map'}
                    />

                    <AdminSideButton
                        navigationLink={'/dashboard'}
                        icon={<DashboardIcon sx={{marginRight: 2}} />}
                        text={'Dashboard'}
                    />

                    { userRole === UserType.ADMIN &&
                        <>
                            <AdminSideButton
                                navigationLink={'/models'}
                                icon={<ModelTrainingIcon sx={{marginRight: 2}} />}
                                text={'Models'}
                            />

                            <Button
                                className={
                                    `${(routePath.pathname === '/interpretation' || routePath.pathname === '/validation' || routePath.pathname == '/testing') 
                                        ? 'admin-side-report-button-active'
                                        : 'admin-side-report-button'}`}
                                sx={{display: 'flex', justifyContent: 'space-between', flexDirection: 'row'}}
                                id="reports-button"
                                aria-controls={open ? 'basic-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={open ? 'true' : undefined}
                                onClick={handleReportsClick}
                            >
                                <div className={'admin-report-button-title-container'}>
                                    <AssessmentIcon sx={{marginRight: 2}} />
                                    Reports
                                </div>
                                {!open ? <ExpandMoreIcon sx={{marginRight: 2}}/> : <ExpandLessIcon sx={{marginRight: 2}}/>}
                            </Button>
                            <Menu
                                id="reports-menu"
                                anchorEl={reportAnchorEl}
                                open={open}
                                onClose={handleClose}
                                MenuListProps={{
                                    'aria-labelledby': 'reports-button',
                                    style: {
                                        width: reportAnchorEl?.offsetWidth
                                    }
                                }}
                                sx={{
                                    width: reportAnchorEl?.offsetWidth
                                }}
                            >
                                <MenuItem onClick={() => {handleClose(); navigation('/validation');}}>Validation</MenuItem>
                                <MenuItem onClick={() => {handleClose(); navigation('/testing');}}>Tests</MenuItem>
                            </Menu>

                            <AdminSideButton
                                navigationLink={'/users'}
                                icon={<ManageAccountsRoundedIcon sx={{marginRight: 2}} />}
                                text={'Users'}
                            />
                        </>
                    }

                    <Button className={'admin-side-button'} sx={{display: 'flex', justifyContent: 'flex-start'}}
                        onClick={() => {
                            logoutUser(); navigation('/')}}
                        >
                        <Logout sx={{marginRight: 2}} />
                        <div>Logout</div>
                    </Button>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default AdminSideMenu