import React, {FC} from "react";
import {Grid} from "@mui/material";
import {aqiMapping} from "../../utils/aqiMapping";


type AQIndexInformationItem = {
    imageSrc: string,
    imageAlt: string,
    indexText: string,
    valuesSpan: string,
    bgColor: string
}


const AQIndexInformationItem:FC<AQIndexInformationItem> = ({imageSrc, imageAlt, indexText, valuesSpan, bgColor}) => {

    return (
        <Grid item xs={12} sm={6} md={6} lg={4}
            className={'aqi-index-information-item-container'}
            sx={{
                padding: 2
            }}
        >
            <img src={imageSrc} alt={imageAlt} style={{maxWidth: '100px', marginBottom: '12px'}} />

            <Grid sx={{display: 'flex', justifyContent: 'center', alignContent: 'center'}}>
                <Grid
                    className={'aqi-index-information-item-text'}
                    sx={{
                        backgroundColor: bgColor,
                        border: `1px solid ${bgColor}`
                    }}
                >
                    {indexText === aqiMapping(101) ?
                        <>
                            {indexText.split(' ')[0]}
                            <p style={{fontSize: '12px', padding: 0, margin: 0, fontWeight: 500}}>
                                {indexText.slice(indexText.indexOf('for')).trim()}
                            </p>
                        </>
                        :
                        indexText
                    }
                </Grid>
                <Grid
                    className={'aqi-index-information-item-values-span'}
                    sx={{
                        border: `1px solid ${bgColor}`
                    }}
                >
                    {valuesSpan}
                </Grid>
            </Grid>
        </Grid>
    )
}

export default AQIndexInformationItem