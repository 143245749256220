import React, {FC} from "react";
import AdminValidationDashboard from "../components/admin/validation/AdminValidationDashboard";
import AdminPageTemplate from "../templates/AdminPageTemplate";


const AdminValidationPage:FC = () => {
    return (
        <AdminPageTemplate children={<AdminValidationDashboard />} />
    )
}

export default AdminValidationPage