import React, {FC, useContext, useState} from "react";
import {
    Button,
    Drawer,
    Grid,
    Menu,
    MenuItem,
    Typography
} from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';
import '../../styles/station-side-menu.css'
import DashboardIcon from '@mui/icons-material/Dashboard';
import ModelTrainingIcon from '@mui/icons-material/ModelTraining';
import HomeIcon from '@mui/icons-material/Home';
import AssessmentIcon from '@mui/icons-material/Assessment';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import {useNavigate} from "react-router-dom";
import {StationContext} from "../../context/StationsContext";
import SideMenuButton from "./SideMenuButton";
import {PredictionType} from "../../types/predictions";
import CurrentPredictionCard from "../home/CurrentPredictionCard";
import BaseProgressBar from "../common/BaseProgressBar";


type StationSideMenuProps = {
}

const StationSideMenu:FC<StationSideMenuProps> = () => {
    const [open, setOpen] = useState<boolean>(false)
    const [reportsOpen, setReportsOpen] = useState<boolean>(false)
    const navigation = useNavigate()
    const [reportAnchorEl, setReportAnchorEl] = useState<null | HTMLElement>(null)
    const { currentData, stations } = useContext(StationContext)

    const handleReportsClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setReportsOpen(true)
        setReportAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
        setReportsOpen(false)
        setTimeout(() => {
            setReportAnchorEl(null);
        }, 300)
    }

    const getStationName = (serial: string) => {
        const found = stations.find((station) => station.serial === serial )

        if (found) return found.label
        else return 'Unknown station'
    }


    return (
        <Grid sx={{
            padding: 1,
            paddingTop: 2,
            backgroundColor: '#0F123F',
            height: '100%',
            maxHeight: '100vh',
            color: 'white',
            overflowY: 'scroll'
        }}>
            <Grid item xs={12}>
                <Button onClick={() => setOpen(true)}>
                    <MenuIcon sx={{color: 'white'}}/>
                </Button>
                <Drawer
                    open={open}
                    anchor={'left'}
                    onClose={() => setOpen(false)}
                    PaperProps={{
                        sx: {
                            backgroundColor: 'transparent',
                            width: {
                                xs: '80%',
                                sm: '60%',
                                md: '40%',
                                lg: '20%'
                            }
                        }
                    }}
                >
                    <Grid item sx={{
                        padding: 1,
                        paddingTop: 4,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                        height: '100%',
                        width: '100%',
                        background: '#F7F7F7',
                        color: '#0F123F',
                        borderTopRightRadius: {
                            xs: 0,
                            sm: '16px'
                        },
                        borderBottomRightRadius: {
                            xs: 0,
                            sm: '16px'
                        }
                    }}>
                        <Typography variant={'h2'} component={'h2'} align={'center'} sx={{fontWeight: 700, letterSpacing: '3px', width: '100%', paddingBottom: 4, userSelect: 'none'}}>
                            Airly
                        </Typography>

                        <SideMenuButton
                            navigationLink={'/'}
                            setOpenFromProps={setOpen}
                            icon={<HomeIcon sx={{marginRight: 2}} />}
                            text={'Home'}
                        />

                        <SideMenuButton
                            navigationLink={'/dashboard'}
                            setOpenFromProps={setOpen}
                            icon={<DashboardIcon sx={{marginRight: 2}} />}
                            text={'Dashboard'}
                        />

                        <SideMenuButton
                            navigationLink={'/models'}
                            setOpenFromProps={setOpen}
                            icon={<ModelTrainingIcon sx={{marginRight: 2}} />}
                            text={'Models'}
                        />

                        <Button
                            className={'drawer-report-button'}
                            sx={{display: 'flex', justifyContent: 'space-between', flexDirection: 'row'}}
                            id="reports-button"
                            aria-controls={reportsOpen ? 'basic-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={reportsOpen ? 'true' : undefined}
                            onClick={handleReportsClick}
                        >
                            <div className={'drawer-report-button-title-container'}>
                                <AssessmentIcon sx={{marginRight: 2}} />
                                Reports
                            </div>
                            {!reportsOpen ? <ExpandMoreIcon sx={{marginRight: 2}}/> : <ExpandLessIcon sx={{marginRight: 2}}/>}
                        </Button>
                        <Menu
                            id="reports-menu"
                            anchorEl={reportAnchorEl}
                            open={reportsOpen}
                            onClose={handleClose}
                            MenuListProps={{
                                'aria-labelledby': 'reports-button',
                                style: {
                                    width: reportAnchorEl?.offsetWidth
                                }
                            }}
                            sx={{
                                width: reportAnchorEl?.offsetWidth
                            }}
                        >
                            {/*<MenuItem onClick={() => {handleClose(); navigation('/interpretation');}}>Interpretations</MenuItem>*/}
                            <MenuItem onClick={() => {handleClose(); navigation('/validation');}}>Validation</MenuItem>
                            <MenuItem onClick={() => {handleClose(); navigation('/testing');}}>Tests</MenuItem>
                        </Menu>
                    </Grid>
                </Drawer>

                <Grid sx={{paddingTop: 5, paddingBottom: 5, paddingLeft: 3, paddingRight: 3}}>
                    <Typography variant={'h3'} component={'h2'} className={'side-menu-title'}
                        sx={{marginBottom: 4}}
                    >
                        Air quality
                    </Typography>


                    <Grid container sx={{columnGap: 2, rowGap: 6, justifyContent: 'center'}}>
                        {currentData ? currentData?.predictions.map((predictionType: PredictionType, index: number) => {
                            return (
                                <Grid key={predictionType.station + '-' + index } item xs={12} sm={5}>
                                    <CurrentPredictionCard
                                        stationName={getStationName(predictionType.station)}
                                        pred_pm10={predictionType.pred_pm10}
                                        pred_pm25={predictionType.pred_pm25}
                                    />
                                </Grid>
                            )
                            })
                            :
                            <BaseProgressBar />
                        }
                    </Grid>

                </Grid>
            </Grid>
        </Grid>
    )
}

export default StationSideMenu