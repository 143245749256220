import React, {FC, useContext, useEffect, useState} from "react";
import Header from "../components/common/Header";
import Footer from "../components/common/Footer";
import {Button, Grid, Typography} from "@mui/material";
import {useResponsive} from "../hooks/responsive";
import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Divider from "@mui/material/Divider";
import {airPollutionApi} from "../utils/axios";
import MessageOkay from "../components/common/MessageOkay";
import MessageError from "../components/common/MessageError";
import {AxiosError} from "axios";
import {AuthContext} from "../context/AuthContext";
import '../styles/login.css'

type LoginPageProps = {}

const CustomTextField = styled(TextField)({
    color: '#000',
    borderBottom: '1px solid #0F123F',
    backgroundColor: 'transparent',
    '& .MuiOutlinedInput-root': {
        '& input': {
            color: '#000',
            fontWeight: 600,
            letterSpacing: '2px'
        },
        '& fieldset': {
            borderColor: 'transparent',
        },
        '&:hover fieldset': {
            borderColor: 'transparent',
        },
        '&.Mui-focused fieldset': {
            borderColor: 'transparent',
        },
    },
});

const LoginPage:FC<LoginPageProps> = () => {
    const isResponsive = useResponsive('down', 'md')
    const [registerEmail, setRegisterEmail] = useState<string>('')
    const [registerPassword, setRegisterPassword] = useState<string>('')
    const [registerPasswordConfirm, setRegisterPasswordConfirm] = useState<string>('')

    const {loginUser, loginError, setLoginError} = useContext(AuthContext)
    const [loginEmail, setLoginEmail] = useState<string>('')
    const [loginPassword, setLoginPassword] = useState<string>('')

    const [registerError, setRegisterError] = useState<string | null>(null)
    const [registerMessage, setRegisterMessage] = useState<string | null>(null)

    const handleRegister = async () => {

        try {
            if(registerPassword !== registerPasswordConfirm) {
                setRegisterError('Passwords don\'t match')
                return
            }
            if(registerEmail === '' || registerPassword === '' || registerPasswordConfirm === '') {
                setRegisterError('Fields cannot be empty')
                return
            }

            const registerResponse = await airPollutionApi.post('/auth/register',
            {email: registerEmail, password: registerPassword, password_confirm: registerPasswordConfirm})

            if (registerResponse.status === 400)
                setRegisterError(registerResponse.data.response)
            else if (registerResponse.status === 200)
                setRegisterMessage('Registration successful')
            else
                setRegisterError('Registration error. Please try again later.')

        } catch (err: any) {
            if (err !== undefined) {
                if (err instanceof AxiosError) {
                    if (err.response?.status === 400) {
                        console.log('register error', err.response)
                        setRegisterError(err.response.data.response)
                    }
                    else
                        setRegisterError('Registration error. Please try again later.')
                }
            }
        }
    }

    useEffect(() => {
        setRegisterError(null)
        setRegisterMessage(null)
    }, [registerEmail, registerPassword, registerPasswordConfirm])

    useEffect(() => {
        setLoginError('')
    }, [loginEmail, loginPassword]);

    return (
        <>
            <Header />
            <Grid container
                sx={{
                    paddingLeft: '5%',
                    paddingRight: '5%',
                    marginTop: isResponsive ? '50px' : '150px',
                    marginBottom: isResponsive ? '100px' : '150px',
                }}
            >
                <Grid item xs={12} md={5}
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'flex-start',
                        gap: 4,
                        height: 'fit-content'
                    }}
                >

                    <Typography variant={'h4'} align={'left'} sx={{fontWeight: 800, letterSpacing: '2px'}}>
                        Login
                    </Typography>

                    <Grid item xs={12} sx={{width: '100%', display: 'flex', flexDirection: 'column', rowGap: 1}}>
                        <Typography sx={{fontWeight: 600, letterSpacing: '2px'}}>Email address</Typography>
                        <CustomTextField
                            id={'login-email'}
                            value={loginEmail}
                            onChange={(e) => setLoginEmail(e.target.value)}
                            type={'email'}
                            sx={{width: isResponsive ? '100%' : '90%'}}
                        />
                    </Grid>

                    <Grid item xs={12} sx={{width: '100%', display: 'flex', flexDirection: 'column', rowGap: 1}}>
                        <Typography sx={{fontWeight: 600, letterSpacing: '2px'}}>Password</Typography>
                        <CustomTextField
                            id={'login-password'}
                            value={loginPassword}
                            onChange={(e) => setLoginPassword(e.target.value)}
                            type={'password'}
                            sx={{width: isResponsive ? '100%' : '90%'}}
                        />
                    </Grid>

                    <Button
                        className={'login-submit-button'}
                        sx={{
                            alignSelf: 'bottom'
                        }}
                        onClick={() => {
                            loginUser(loginEmail, loginPassword)
                        }}
                    >
                        Login
                    </Button>

                    <Grid item xs={12} sx={{width: isResponsive ? '100%' : '90%'}}>
                        {loginError && <MessageError text={loginError} />}
                    </Grid>
                </Grid>

                {isResponsive ?
                    <Divider orientation={'horizontal'} variant={'fullWidth'} sx={{width: '100%', marginTop: 4, marginBottom: 5}} />
                :
                    <Grid item xs={2} sx={{width: '100%', display: 'flex', justifyContent: 'center'}}>
                        <Divider orientation={'vertical'} variant="middle" />
                    </Grid>
                }

                <Grid item xs={12} md={5} sx={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start', gap: 4}}>

                    <Typography variant={'h4'} align={'left'} sx={{fontWeight: 800, letterSpacing: '2px'}}>
                        Register
                    </Typography>

                    <Grid item xs={12} sx={{width: '100%', display: 'flex', flexDirection: 'column', rowGap: 1}}>
                        <Typography sx={{fontWeight: 600, letterSpacing: '2px'}}>Email address</Typography>
                        <CustomTextField
                            id={'registration-email'}
                            value={registerEmail}
                            onChange={(e) => setRegisterEmail(e.target.value)}
                            type={'email'}
                            sx={{width: isResponsive ? '100%' : '90%'}}
                        />
                    </Grid>

                    <Grid item xs={12} sx={{width: '100%', display: 'flex', flexDirection: 'column', rowGap: 1}}>
                        <Typography sx={{fontWeight: 600, letterSpacing: '2px'}}>Password</Typography>
                        <CustomTextField
                            id={'registration-password'}
                            value={registerPassword}
                            onChange={(e) => setRegisterPassword(e.target.value)}
                            type={'password'}
                            sx={{width: isResponsive ? '100%' : '90%'}}
                        />
                    </Grid>

                    <Grid item xs={12} sx={{width: '100%', display: 'flex', flexDirection: 'column', rowGap: 1}}>
                        <Typography sx={{fontWeight: 600, letterSpacing: '2px'}}>Confirm password</Typography>
                        <CustomTextField
                            id={'registration-password-confirm'}
                            value={registerPasswordConfirm}
                            onChange={(e) => setRegisterPasswordConfirm(e.target.value)}
                            type={'password'}
                            sx={{width: isResponsive ? '100%' : '90%'}}
                        />
                    </Grid>

                    <Button
                        className={'login-submit-button'}
                        sx={{
                            alignSelf: 'bottom',
                        }}
                        onClick={() => {setRegisterMessage(null); setRegisterError(null); handleRegister()}}
                    >
                        Register
                    </Button>

                    <Grid item xs={12} sx={{width: isResponsive ? '100%' : '90%'}}>
                        {registerMessage && <MessageOkay text={registerMessage} />}
                        {registerError && <MessageError text={registerError} />}
                    </Grid>
                </Grid>
            </Grid>

            <Footer />
        </>
    )
}

export default LoginPage